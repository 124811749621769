import React from 'react'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import infMessage1 from '../../assets/24/infmessages/ИНФОРМАЦИОННОЕ ПИСЬМО №1.docx.pdf'
import infMessage2 from '../../assets/24/infmessages/ИНФОРМАЦИОННОЕ ПИСЬМО №2.rtf'
import infMessage3 from '../../assets/24/infmessages/ИНФОРМАЦИОННОЕ ПИСЬМО №3.rtf'
import infMessage4 from '../../assets/24/infmessages/ИНФОРМАЦИОННОЕ ПИСЬМО №4.docx'
// import resolves from '../../assets/files22/Решение УОМШ-22.pdf'

const News = () => {
    return (
        <LayoutPage headling='Новости'>
            <>
                <ul>
                    <li><a href={infMessage1}>Информационное письмо 1</a></li>
                    <li><a href={infMessage2}>Информационное письмо 2</a></li>
                    <li><a href={infMessage3}>Информационное письмо 3</a></li>
                    <li><a href={infMessage4}>Информационное письмо 4</a></li>
                </ul>

            </>
        </LayoutPage>
    )
}

export default News
