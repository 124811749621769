import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { useEffect } from 'react';
import { arrayToData, throttle } from '../../helpers/utils';
import { data } from './Members2024/data'

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 15,
            marginTop: 10,
        },
    },
    heading: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            padding: 4,
            marginTop: 10,
            flexDirection: 'column',
        },
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
    },
    title: {
        [theme.breakpoints.down('md')]: {
            fontSize: '1.9rem',
        },
    },
    inputs: {
        display: 'flex',
        flexDirection: 'column',
        width: '260px',
    },
}));


export default function Members() {
    // const sheetURL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQBPPhgwBAY3Y-S_iIKFPNmYJVNYFQ9_HQyodN-yFP8O-PQRdNKhtZlFh8lDlO9g9gWsIHb_u6QWhBW/pubhtml' // 23 год
    // const sheetURL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vT94obUnl1ppkZod1Dd8FrOlvtl88ivrMLL7w5SdTHngYAo_w37s6zOEmEbnXqcoMK-qPJ6QYmGV6op/pubhtml?gid=0&single=true'

    // const [initialState, setMembersList] = useState(data)
    const initialState = data

    // useEffect(() => {
    //     const parser = new DOMParser();
    //     fetch(sheetURL)
    //         .then(response => response.text())
    //         .then(data => {
    //             const doc = parser.parseFromString(data, 'text/html')
    //             const body = doc.querySelector('tbody')
    //             const trs = body.querySelectorAll('tr')

    //             const dataList = []
    //             trs.forEach((tr) => {
    //                 const array = [];
    //                 const tds = tr.querySelectorAll('td')
    //                 tds.forEach((td) => {
    //                     array.push(td.textContent.trim());
    //                 });
    //                 dataList.push(array)
    //             });
    //             dataList.shift()
    //             setMembersList(arrayToData(dataList))
    //             setMembers(arrayToData(dataList))
    //         })
    //         .catch(error => {
    //             console.error('Произошла ошибка:', error);
    //         });
    // }, [])


    const classes = useStyles();
    const [members, setMembers] = useState(initialState);
    const filterMember = (e) => {
        let variant;
        e.currentTarget.id === 'search-name' ? (variant = 'name') : (variant = 'body');

        const value = e.target.value;
        let copy = [...initialState];
        variant === 'name'
            ? setMembers(copy.filter((el) => el.name.search(new RegExp(value, 'ig')) !== -1))
            : setMembers(copy.filter((el) => el.body.search(new RegExp(value, 'ig')) !== -1));
    };
    console.log('members', members)
    return (
        <Container maxWidth='lg' className={classes.container}>
            <Paper className={classes.paper}>
                <Box p={4} className={classes.heading}>
                    <Typography variant='h3' className={classes.title}>
                        Принятые заявки: {initialState.length}
                    </Typography>
                    <Box className={classes.inputs}>
                        <TextField
                            id='search-name'
                            size='small'
                            fullWidth
                            onChange={throttle(filterMember, 400)}
                            label='Поиск по фамилии'
                        />
                        <TextField
                            id='search-body'
                            size='small'
                            fullWidth
                            onChange={filterMember}
                            label='Поиск по названию'
                        />
                    </Box>
                </Box>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>№</TableCell>
                                <TableCell align='center'>ФИО</TableCell>
                                <TableCell align='center'>Название доклада</TableCell>
                                <TableCell align='center'>Организация</TableCell>
                                <TableCell align='center'>Город</TableCell> */}
                                <TableCell align="left">№</TableCell>
                                <TableCell align="left">ФИО</TableCell>
                                <TableCell align="left">Место работы (учебы)</TableCell>
                                <TableCell align="left">Название доклада</TableCell>
                                <TableCell align="right">Секция</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {members.map((row, index) => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component='th' scope='row'>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.body}</TableCell>
                                    <TableCell>{row.organization}</TableCell>
                                    <TableCell>{row.from}</TableCell> */}
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">{row.from}</TableCell>
                                    <TableCell align="left">{row.title}</TableCell>
                                    <TableCell align="left">{row.section}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Container>
    );
}
