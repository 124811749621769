import React from 'react'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import { shortName } from '../../constants'
import collection1 from '../../assets/24/tezis/УОМШ-2024-1.pdf'
import collection2 from '../../assets/24/tezis/УОМШ-2024-2.pdf'

const Collection = () => {
    return (
        <LayoutPage headling={`Сборник тезисов ${shortName}`}>
            <ul>
                <li><a href={collection1}>Тезисы {shortName}. Том 1</a></li>
                <li><a href={collection2}>Тезисы {shortName}. Том 2</a></li>
            </ul>
        </LayoutPage>
    )
}

export default Collection
