import React from "react";
import Typography from "@material-ui/core/Typography";
import LayoutPage from "../../hoc/LayoutPage/LayoutPage";
import { rows } from "./data";
import i0 from '../../assets/24/doclads/Акманова С. (НУ).pdf'
import i1 from '../../assets/24/doclads/Атанов А.В. (КФА).pdf'
import i2 from '../../assets/24/doclads/Багиров Ш.Г. (НУ).pdf'
import i3 from '../../assets/24/doclads/Бадулина Н. А. (КФА).pdf'
import i4 from '../../assets/24/doclads/Великанов_Хуан Жуй_ (ДУ).pdf'
import i5 from '../../assets/24/doclads/Гермидер О., Попов В. (НУ).pdf'
import i6 from '../../assets/24/doclads/Даллул Мариана  (КФА).pdf'
import i7 from '../../assets/24/doclads/Дусанова У. (ДУ).pdf'
import i8 from '../../assets/24/doclads/Евтина Д, Жабко А. (ДУ).pdf'
import i9 from '../../assets/24/doclads/Жабко А., Гапонов Д. (ММ).pdf'
import i10 from '../../assets/24/doclads/Зацепин Д. (КФА).pdf'
import i11 from '../../assets/24/doclads/Зверева М.Б. (ДУ).pdf'
import i12 from '../../assets/24/doclads/КАшникова А. (MM).pdf'
import i13 from '../../assets/24/doclads/Качалов В., Маслов Д. (НУ).pdf'
import i14 from '../../assets/24/doclads/Костенко И., Малютин К.  (КФА).pdf'
import i15 from '../../assets/24/doclads/Кризкий_Александров_Владов (ММ).pdf'
import i16 from '../../assets/24/doclads/Лискина_Алыпов_(ММ).pdf'
import i17 from '../../assets/24/doclads/Ломакина Е., Деревянко О. (КФА).pdf'
import i18 from '../../assets/24/doclads/Лукащук С., Спеле В. (ДУ).pdf'
import i19 from '../../assets/24/doclads/Максимов В.П. (ДУ).pdf'
import i20 from '../../assets/24/doclads/Малыгина А.С.  (ММ).pdf'
import i21 from '../../assets/24/doclads/Мальцева С., Деревцов Е. (ММ).pdf'
import i22 from '../../assets/24/doclads/Мурзагулова Ж., Мурзагулов Р. (НУ).pdf'
import i23 from '../../assets/24/doclads/Мухаметшин А. и др. (ММ).pdf'
import i24 from '../../assets/24/doclads/Наумова А.А. (КФА).pdf'
import i25 from '../../assets/24/doclads/Николаева Н., Манакова Н. (ДУ).pdf'
import i26 from '../../assets/24/doclads/Рассадин А., Рубцов М., Бугров В. (ММ).pdf'
import i27 from '../../assets/24/doclads/Россовский Г. (ДУ).pdf'
import i28 from '../../assets/24/doclads/Рябков К. (ММ).pdf'
import i29 from '../../assets/24/doclads/Сакиева А. (НУ).pdf'
import i30 from '../../assets/24/doclads/Сафонова Т. (СТО).pdf'
import i31 from '../../assets/24/doclads/Солиев Ю.С. (КФА).pdf'
import i32 from '../../assets/24/doclads/Степанов А., Чуновкина А. (ММ).pdf'
import i33 from '../../assets/24/doclads/Тимиргалеев С. (ДУ).pdf'
import i34 from '../../assets/24/doclads/Уфимская В.А. (ММ).pdf'
import i35 from '../../assets/24/doclads/Хадур Махмуд  (КФА).pdf'
import i36 from '../../assets/24/doclads/Хакимов А. (НУ).pdf'
import i37 from '../../assets/24/doclads/Читоркин Е. (СТО).pdf'
import i38 from '../../assets/24/doclads/Шабалин П. (ДУ).pdf'
import i39 from '../../assets/24/doclads/Эгамов А.И., Бураго П.Н. (ММ).pdf'
import { Box, makeStyles } from "@material-ui/core";

import { shortName } from "../../constants";

const StendReport = () => {

  const variables = [i0,
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    i7,
    i8,
    i9,
    i10,
    i11,
    i12,
    i13,
    i14,
    i15,
    i16,
    i17,
    i18,
    i19,
    i20,
    i21,
    i22,
    i23,
    i24,
    i25,
    i26,
    i27,
    i28,
    i29,
    i30,
    i31,
    i32,
    i33,
    i34,
    i35,
    i36,
    i37,
    i38,
    i39,]

  return (
    <LayoutPage headling="Стендовые доклады">
      <>
        <Typography variant="h3" paragraph>
          Регламент работы стендовой сессии {shortName}
        </Typography>
        <Typography variant="body1" paragraph>
          Стендовая сессия конференции {shortName} <b>состоится 5 октября</b> (10:00 - 12:00 - время уфимское).
        </Typography>
        <Typography variant="body1" paragraph>
          Стендовая сессия будет проходить в онлайн-формате с использованием ZOOM (ссылки на ZOOM-конференцию <b>определятся 2 октября</b> и будут доступны на сайте конференции  <a href="www.conf-bashedu-fmit.ru">www.conf-bashedu-fmit.ru</a>),  Стендовая сессия будет проходить по  секциям.
        </Typography>
        <Typography variant="body1" paragraph>
          Авторам стендовых докладов предлагается  подготовить презентацию своего доклада и выслать ее на e-mail  конференции <a href="mailto:ufa.mat.sh@mail.ru">ufa.mat.sh@mail.ru</a>  не позднее 2 октября. Все презентации будут доступны для ознакомления на сайте конференции
        </Typography>
        <Typography variant="body1" paragraph>
          Перед началом работы стендовой сессии авторам докладов необходимо подключиться к ZOOM-конференции и быть доступным для обсуждения своего доклада. Заинтересованным участникам конференции также необходимо подключиться к ZOOM-конференции.
        </Typography>
        <Typography variant="body1" paragraph>
          Во время проведения стендовой сессии каждый участник может задавать вопросы авторам докладов. Обсуждение вопросов будет проходить в форме дискуссии (до 10 минут)  между слушателем и автором доклада, во время которой на экране демонстрируется презентация доклада.
        </Typography>

        <Box sx={{ marginTop: '30px' }} />
        <Typography variant="h4">
          Спектральная теория. Нелинейные уравнения.
        </Typography>
        <Typography variant="body1" >Войти Zoom Конференция</Typography>
        <Typography variant="body1" ><a href="https://us05web.zoom.us/j/89383805233?pwd=YRTXcDQ22wrc1yPAveHs4zXc6bHMbC.1">https://us05web.zoom.us/j/89383805233?pwd=YRTXcDQ22wrc1yPAveHs4zXc6bHMbC.1</a></Typography>
        <Typography variant="body1" >Идентификатор конференции: 893 8380 5233</Typography>
        <Typography variant="body1" >Код доступа: 5Ues7c</Typography>
        <Box sx={{ marginTop: '16px' }} />
        <Typography variant="h4">
          Дифференциальные уравнения
        </Typography>
        <Typography variant="body1" >Войти Zoom Конференция</Typography>
        <Typography variant="body1" ><a href="https://us05web.zoom.us/j/87903222328?pwd=RzYGQdTdh2mftySuszl8xpTeoLtodQ.1">https://us05web.zoom.us/j/87903222328?pwd=RzYGQdTdh2mftySuszl8xpTeoLtodQ.1</a></Typography>
        <Typography variant="body1" >Идентификатор конференции: 879 0322 2328</Typography>
        <Typography variant="body1" >Код доступа: h7vT12</Typography>
        <Box sx={{ marginTop: '16px' }} />
        <Typography variant="h4">
          Комплексный и функциональный анализ
        </Typography>
        <Typography variant="body1" >Войти Zoom Конференция</Typography>
        <Typography variant="body1" ><a href="https://us05web.zoom.us/j/88635590384?pwd=5kzab1ibzafLbL31QP0Va8wYtNSQqn.1">https://us05web.zoom.us/j/88635590384?pwd=5kzab1ibzafLbL31QP0Va8wYtNSQqn.1</a></Typography>
        <Typography variant="body1" >Идентификатор конференции: 886 3559 0384</Typography>
        <Typography variant="body1" >Код доступа: 03AGKa</Typography>
        <Box sx={{ marginTop: '16px' }} />
        <Typography variant="h4">
          Математическое моделирование
        </Typography>
        <Typography variant="body1" >Войти Zoom Конференция</Typography>
        <Typography variant="body1" ><a href="https://us05web.zoom.us/j/81318504579?pwd=q70z361Z09SxKxiGtDKXelrY4wrhoT.1">https://us05web.zoom.us/j/81318504579?pwd=q70z361Z09SxKxiGtDKXelrY4wrhoT.1</a></Typography>
        <Typography variant="body1" >Идентификатор конференции: 813 1850 4579</Typography>
        <Typography variant="body1" >Код доступа: i5fWWE</Typography>

        <Box sx={{ marginTop: '30px' }} />
        <Typography variant="h3" paragraph>
          Доклады участников конференции
        </Typography>
        {/* <Typography variant="body1">
          в соответствующей Zoom-конференции.
        </Typography>
        <Typography variant="h6" paragraph>
          Ссылки на Zoom-конференцию:
        </Typography>
        <Typography variant="body1">
          Подключиться к конференции Zoom{" "}
        </Typography>
        <Link
          href="https://us02web.zoom.us/j/88341611497?pwd=QWx6MVNyS1huZmFJVEl6di9CcXNMZz09"
          color="primary"
        >
          https://us02web.zoom.us/j/88341611497?pwd=QWx6MVNyS1huZmFJVEl6di9CcXNMZz09
        </Link> */}

        {/* <Typography variant="body1">
          Идентификатор конференции: 883 4161 1497
        </Typography>
        <Typography variant="body1" paragraph>
          Код доступа: 123
        </Typography>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Авторам стендовых докладов необходимо войти в Zoom-конференцию в
          10:00.{" "}
        </Typography> */}
        <ol>

          {rows.map((row, index) =>
            <Typography component='li' variant="subtitle1">
              <a href={variables[index]}>{row}</a>
            </Typography>
          )}
        </ol>
      </>
    </LayoutPage >
  );
};

export default StendReport;
