export const rows = [
    'Акманова С. (НУ).pdf',
    'Атанов А.В. (КФА).pdf',
    'Багиров Ш.Г. (НУ).pdf',
    'Бадулина Н. А. (КФА).pdf',
    'Великанов Хуан Жуй (ДУ).pdf',
    'Гермидер О., Попов В. (НУ).pdf',
    'Даллул Мариана  (КФА).pdf',
    'Дусанова У. (ДУ).pdf',
    'Евтина Д, Жабко А. (ДУ).pdf',
    'Жабко А., Гапонов Д. (ММ).pdf',
    'Зацепин Д. (КФА).pdf',
    'Зверева М.Б. (ДУ).pdf',
    'Кашникова А. (MM).pdf',
    'Качалов В., Маслов Д. (НУ).pdf',
    'Костенко И., Малютин К.  (КФА).pdf',
    'Кризкий Александров Владов (ММ).pdf',
    'Лискина Алыпов (ММ).pdf',
    'Ломакина Е., Деревянко О. (КФА).pdf',
    'Лукащук С., Спеле В. (ДУ).pdf',
    'Максимов В.П. (ДУ).pdf',
    'Малыгина А.С.  (ММ).pdf',
    'Мальцева С., Деревцов Е. (ММ).pdf',
    'Мурзагулова Ж., Мурзагулов Р. (НУ).pdf',
    'Мухаметшин А. и др. (ММ).pdf',
    'Наумова А.А. (КФА).pdf',
    'Николаева Н., Манакова Н. (ДУ).pdf',
    'Рассадин А., Рубцов М., Бугров В. (ММ).pdf',
    'Россовский Г. (ДУ).pdf',
    'Рябков К. (ММ).pdf',
    'Сакиева А. (НУ).pdf',
    'Сафонова Т. (СТО).pdf',
    'Солиев Ю.С. (КФА).pdf',
    'Степанов А., Чуновкина А. (ММ).pdf',
    'Тимиргалеев С. (ДУ).pdf',
    'Уфимская В.А. (ММ).pdf',
    'Хадур Махмуд  (КФА).pdf',
    'Хакимов А. (НУ).pdf',
    'Читоркин Е. (СТО).pdf',
    'Шабалин П. (ДУ).pdf',
    'Эгамов А.И., Бураго П.Н. (ММ).pdf'
  ];