import React from 'react'
import Typography from '@material-ui/core/Typography'
import LayoutPage from '../../hoc/LayoutPage/LayoutPage'
import programm from '../../assets/24/Программа УОМШ-24.pdf'
// import programm from '../../assets/24/Предварительная_программа_УОМШ_24.doc'
import { shortName } from '../../constants'

const Program = () => {
	return (
		<LayoutPage headling='Программа конференции'>
			<>
				{/* <Typography variant='body1' paragraph>Программа секций</Typography> */}
				<ul>
					<li><a href={programm}>Программа конференции {shortName}</a></li>
					{/* <li><a href={programm}>Программа конференции {shortName}</a></li> */}
					{/* <li><a href={anotation1}>Аннотация Часть 1</a></li>
                    <li><a href={anotation2}>Аннотация Часть 2</a></li> */}
				</ul>
			</>
			{/* <Typography variant='h5' align='center' paragraph component='h2'>
					<strong>Уважаемые участники конференции!</strong>
					</Typography>
					<Typography variant='body1' >Мы напоминаем, что наша конференция будет проводиться в двух форматах: offline и online  на платформе ZOOM.</Typography>
					<Typography variant='h6' >Пленарные доклады</Typography>
					<Typography variant='body1' > Ссылка на ZOOM <a href="https://us05web.zoom.us/j/87504237585?pwd=SFNmQUIvT0tRaHlDaVYrN3l5bzJVQT09">https://us05web.zoom.us/j/87504237585?pwd=SFNmQUIvT0tRaHlDaVYrN3l5bzJVQT09</a></Typography>					
					<Typography variant='body1' >Идентификатор конференции: 875 0423 7585</Typography>
					<Typography variant='body1' >Код доступа: 1</Typography>

					<Typography variant='h6' >Секция «Спектральная теория операторов»</Typography>
					<Typography variant='body1' > Ссылка на ZOOM <a href="https://us05web.zoom.us/j/87504237585?pwd=SFNmQUIvT0tRaHlDaVYrN3l5bzJVQT09">https://us05web.zoom.us/j/87504237585?pwd=SFNmQUIvT0tRaHlDaVYrN3l5bzJVQT09</a></Typography>					
					<Typography variant='body1' >Идентификатор конференции: 875 0423 7585</Typography>
					<Typography variant='body1' >Код доступа: 1</Typography>

					<Typography variant='h6' >Секция «Комплексный и функциональный анализ»</Typography>
					<Typography variant='body1' > Ссылка на ZOOM <a href="https://us05web.zoom.us/j/85373003642?pwd=b3M4cFJxUHFnZnpuU3kyWW8vNzg0QT09">https://us05web.zoom.us/j/85373003642?pwd=b3M4cFJxUHFnZnpuU3kyWW8vNzg0QT09</a></Typography>					
					<Typography variant='body1' >Идентификатор конференции:  853 7300 3642</Typography>
					<Typography variant='body1' >Код доступа: 1</Typography>

					<Typography variant='h6' >Секция «Нелинейные уравнения»</Typography>
					<Typography variant='body1' > Ссылка на ZOOM <a href="https://us05web.zoom.us/j/89498189565?pwd=SHAzL1kwTmcyYm1PdkdzNVNLNUZ6dz09">https://us05web.zoom.us/j/89498189565?pwd=SHAzL1kwTmcyYm1PdkdzNVNLNUZ6dz09</a></Typography>					
					<Typography variant='body1' >Идентификатор конференции: 894 9818 9565</Typography>
					<Typography variant='body1' >Код доступа: 1</Typography>

					<Typography variant='h6' >Секция «Нелинейные уравнения»</Typography>
					<Typography variant='body1' > Ссылка на ZOOM <a href="https://us05web.zoom.us/j/89250220135?pwd=dTVreXJLQXFzdVNrTUp0aVpZUzdJUT09">https://us05web.zoom.us/j/89250220135?pwd=dTVreXJLQXFzdVNrTUp0aVpZUzdJUT09</a></Typography>					
					<Typography variant='body1' >Идентификатор конференции: 892 5022 0135</Typography>
					<Typography variant='body1' >Код доступа: 1</Typography> */}
		</LayoutPage>
	)
}

export default Program
