export const data = [
    {
        "id": 1,
        "name": "Федоров Владимир Евгеньевич, Филин Николай Владимирович",
        "from": "Челябинский государственный университет, Челябинск",
        "title": "Принцип субординации для одного класса уравнений с распределенной производной",
        "section": "ДУ"
    },
    {
        "id": 2,
        "name": "Кузнецова Мария Андреевна",
        "from": "Саратовский государственный университет, Саратов",
        "title": "О задаче восстановления операторов с двумя замороженными аргументами по двум спектрам",
        "section": "СТО"
    },
    {
        "id": 3,
        "name": "Мухаметшин Андрей Ренатович, Баймурзин Даниил Андреевич, Богданова Анастасия Петровна, Каменских Анна Александровна, Носов Юрий Олегович",
        "from": "ПНИПУ (Пермь, Россия)",
        "title": "СРАВНИТЕЛЬНЫЙ АНАЛИЗ\n\nНАПРЯЖЕННО-ДЕФОРМИРОВАННОГО СОСТОЯНИЯ\n\nПОЛИМЕРНОГО СЛОЯ СКОЛЬЖЕНИЯ ПРИ УПРУГОМ,\n\nУПРУГОПЛАСТИЧЕСКОМ И ВЯЗКОУПРУГОМ\n\nПОВЕДЕНИИ МАТЕРИАЛА",
        "section": "ММ"
    },
    {
        "id": 4,
        "name": "Bagirov Shirmayil Hasan",
        "from": "Бакинский Государственный Университет, Баку",
        "title": "ABSENCE OF GLOBAL SOLUTIONS TO A SYSTEM OF HIGH-ORDER SEMILINEAR EQUATIONS WITH A SINGULAR POTENTIAL",
        "section": "НУ"
    },
    {
        "id": 5,
        "name": "Макин Александр Сергеевич",
        "from": "Институт прикладной математики и механики, Донецк",
        "title": "Об операторе Дирака с нерегулярными краевыми условиями",
        "section": "СТО"
    },
    {
        "id": 6,
        "name": "Евхута Николай Александрович",
        "from": "Южно-Российский государственный политехнический университет (НПИ) им. М.И. Платова",
        "title": "Периодические решения некоторых уравнений",
        "section": "ДУ"
    },
    {
        "id": 7,
        "name": "Евхута Ольга Николаевна",
        "from": "Южно-Российский государственный политехнический университет (НПИ) им. М.И. Платова",
        "title": "О сходимости метода минимальных ошибок",
        "section": "КФА"
    },
    {
        "id": 8,
        "name": "Уфимская Василиса Алексеевна, Пустовалов Дмитрий Олегович, Шаров Константин Владимирович, Абляз Тимур Ризович",
        "from": "ПНИПУ (Пермь, Россия)",
        "title": "МОДЕЛИРОВАНИЕ ПРОЦЕССА ЗАЛИВКИ И ЗАТВЕРДЕВАНИЯ ЛИТЫХ ЗАГОТОВОК ТОПОЛОГИЧЕСКИ ОПТИМИЗИРОВАННЫХ ДЕТАЛЕЙ",
        "section": "ММ"
    },
    {
        "id": 9,
        "name": "Шмидт Елизавета Сергеевна",
        "from": "Петрозаводский государственный университет, Петрозаводск",
        "title": "О неравенствах типа Бернштейна и Смирнова для комплексных многочленов",
        "section": "КФА"
    },
    {
        "id": 10,
        "name": "Рустанов Алигаджи Рабаданович, Харитонова Светлана Владимировна",
        "from": "НИУ МГСУ (Москва, Россия)\n\nОГУ (Оренбург, Россия)",
        "title": "Геометрия конформно плоских гармонических приближенно транссасакиевых многообразий",
        "section": "НУ"
    },
    {
        "id": 11,
        "name": "Мурясов Роман Русланович",
        "from": "Институт математики с вычислительным центром УФИЦ РАН, Уфа",
        "title": "О СУБГАРМОНИЧНОСТИ ФУНКЦИЙ С РАЗДЕЛЁННЫМИ ПЕРЕМЕННЫМИ И ИХ СВЯЗИ С СУБСФЕРИЧЕСКИМИ ФУНКЦИЯМИ",
        "section": "КФА"
    },
    {
        "id": 12,
        "name": "Аббас Хайдар Хамед, Савин Антон Юрьевич",
        "from": "РУДН (Москва, Россия)",
        "title": "ЛОКАЛИЗОВАННЫЙ ТОПОЛОГИЧЕСКИЙ ИНДЕКС И НЕПОДВИЖНЫЕ ТОЧКИ",
        "section": "ДУ"
    },
    {
        "id": 13,
        "name": "Dusanova Umida Xomid qizi",
        "from": "Математический институт имени В.И. Романовского, Ташкент",
        "title": "FORWARD AND INVERSE PROBLEMS FOR A MIXED TYPE EQUATION WITH THE GERASIMOV–CAPUTO FRACTIONAL DERIVATIVE",
        "section": "ДУ"
    },
    {
        "id": 14,
        "name": "Качалов Василий Иванович, Маслов Дмитрий Александрович",
        "from": "НИУ МЭИ (Москва, Россия)",
        "title": "Об одном типе слабо нелинейного возмущения спектра линейного оператора",
        "section": "СТО"
    },
    {
        "id": 15,
        "name": "Леднов Александр Петрович",
        "from": "Саратовский государственный университет им. Н.Г. Чернышевского, Саратов",
        "title": "Об успокоении системы управления на графе-звезде с глобальным запаздыванием, пропорциональным времени",
        "section": "ДУ"
    },
    {
        "id": 16,
        "name": "Лукашук Станислав Юрьевич, Спеле Владимир Владимирович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "Построение приближенного решения дробно-дифференциального обобщения уравнения Гельмгольца",
        "section": "ДУ"
    },
    {
        "id": 17,
        "name": "Казакбаева Каллигул Бахтияр кизи",
        "from": "Иниститут Математики им. В.И. Романовского Академии наук Республики Узбекистан",
        "title": "Нелокальная краевая задача для уравнения смешанного типа в области, эллиптическая часть которой полуполоса",
        "section": "ДУ"
    },
    {
        "id": 18,
        "name": "Гермидер Оксана Владимировна, Попов Василий Николаевич",
        "from": "САФУ имени М.В. Ломоносова (Архангельск, Россия)",
        "title": "О решении нелинейного интегрального уравнения Фредгольма второго рода специального вида",
        "section": "НУ"
    },
    {
        "id": 19,
        "name": "Степанов Александр Владимирович, Чуновкина Анна Гурьевна",
        "from": "ВНИИМ им. Д.И. Менделеева (Санкт-Петербург, Россия)\n\nГУАП (Санкт-Петербург, Россия)",
        "title": "О применении TSP-распределения при анализе многократных неравноточных результатов измерений",
        "section": "ММ"
    },
    {
        "id": 20,
        "name": "Файзулина Кира Игоревна, Хакимова Айгуль Ринатовна",
        "from": "Институт математики с вычислительным центром Уфимского научного центра Российской академии наук, Уфа",
        "title": "Редукция последовательности Лапласа и уравнения типа синус-Гордона",
        "section": "НУ"
    },
    {
        "id": 21,
        "name": "Скрипка Надежда Михайловна, Федоров Владимир Евгеньевич",
        "from": "ЧелГУ (Челябинск, Россия)",
        "title": "Вырожденное эволюционное уравнение с производной Лиувилля",
        "section": "ДУ"
    },
    {
        "id": 22,
        "name": "Читоркин Егор Евгеньевич",
        "from": "Самарский национальный исследовательский университет имени академика С.П. Королёва, Самара; Саратовский национальный исследовательский государственный университет имени Н.Г. Чернышевского, Саратов",
        "title": "Обратная задача Штурма-Лиувилля с многочленами в краевом условии и кратными собственными значениями",
        "section": "СТО"
    },
    {
        "id": 23,
        "name": "Хабибуллин Булат Нурмиевич",
        "from": "Институт математики УФИЦ РАН, Уфа",
        "title": "Уменьшение роста (плюри)субгармонических и целых  функций",
        "section": "КФА"
    },
    {
        "id": 24,
        "name": "Акманов Арслан Айтуганович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "Геометрические приложения дифференциального исчисления: формула  для вычисления кривизны плоской кривой",
        "section": "КФА"
    },
    {
        "id": 25,
        "name": "Зотова Екатерина Игоревна",
        "from": "Уфимский университет науки и технологий, Уфа",
        "title": "О стохастических уравнениях Клейна-Гордона и синус-Гордона",
        "section": "НУ"
    },
    {
        "id": 26,
        "name": "Шишацкая Полина Сергеевна, Федоров Владимир Евгеньевич,",
        "from": "Челябинский государственный университет (Челябинск, Россия)",
        "title": "РАЗРЕШИМОСТЬ УРАВНЕНИЯ ВЫСОКОГО ПОРЯДКА\n\nНА ПРЯМОЙ В КЛАССЕ ЭКСПОНЕНЦИАЛЬНО\n\nОГРАНИЧЕННЫХ ФУНКЦИЙ",
        "section": "ДУ"
    },
    {
        "id": 27,
        "name": "Кузнецова Мария Андреевна",
        "from": "Саратовский государственный университет, Саратов",
        "title": "О задаче восстановления операторов с двумя замороженными аргументами по двум спектрам",
        "section": "СТО"
    },
    {
        "id": 28,
        "name": "Борисов Денис Иванович",
        "from": "Институт математики с вычислительным центром УФИЦ РАН",
        "title": "Об экзотических собственных значениях графов с малыми ребрами",
        "section": "СТО"
    },
    {
        "id": 29,
        "name": "Мелехина Дарья Владимировна, Федоров Владимир Евгеньевич",
        "from": "Челябинский государственный университет (Челябинск, Россия)",
        "title": "Нелинейная обратная задача для дробной по времени системы Кельвина – Фойгта",
        "section": "ДУ"
    },
    {
        "id": 30,
        "name": "Мажгихова Мадина Гумаровна",
        "from": "Институт прикладной математики и автоматизации КБНЦ РАН, Нальчик",
        "title": "Краевая задача с обобщенными условиями типа Штурма для дифференциального уравнения дробного порядка с запаздывающим аргументом",
        "section": "ДУ"
    },
    {
        "id": 31,
        "name": "Богатырева Фатима Тахировна",
        "from": "Институт прикладной математики и автоматизации КБНЦ РАН, Нальчик",
        "title": "О разрешимости уравнения диффузии с операторами Джрбашяна-Нерсесяна",
        "section": "ДУ"
    },
    {
        "id": 32,
        "name": "Похачевский Всеволод Андреевич",
        "from": "Московский государственный университет, Москва",
        "title": "О лебеговых множествах слабых показателей колеблемости корней решений линейных систем",
        "section": "ДУ"
    },
    {
        "id": 33,
        "name": "Муканов Асхат Бирлесович\n\nНурсултанов Ерлан Даутбекович",
        "from": "Institute of Mathematics and Mathematical Modeling, Almaty, Kazakhstan\n\nКазахстанский филиал\n\nМГУ имени М.В. Ломоносова",
        "title": "О теореме Харди-Литтлвуда",
        "section": "КФА"
    },
    {
        "id": 34,
        "name": "Бармак Белла Давидовна",
        "from": "Московский государственный университет, Москва",
        "title": "Формула для суммы одного условно сходящегося ряда",
        "section": "СТО"
    },
    {
        "id": 35,
        "name": "Малыгина Анжела Сергеевна, Пышнограй Григорий Владимирович",
        "from": "АлтГУ (Барнаул, Россия)\n\nАлтГТУ имени И.И.\n\nПолзунова (Барнаул, Россия)",
        "title": "ОБОСНОВАНИЕ РЕОЛОГИЧЕСКОЙ МОДЕЛИ ДЛЯ ОПИСАНИЯ ВЯЗКОУПРУГОСТИ ПОЛИМЕРНЫХ СИСТЕМ",
        "section": "ММ"
    },
    {
        "id": 36,
        "name": "Подклетнова Светлана Владимировна",
        "from": "Самарский университет, Самара",
        "title": "Задача E для одного частного случая уравнения Эйлера-Дарбу",
        "section": "ДУ"
    },
    {
        "id": 37,
        "name": "Качалов Василий Иванович, Маслов Дмитрий Александрович",
        "from": "НИУ МЭИ\n\n(Москва, Россия)\n\n(",
        "title": "Метод малого параметра в теории нелинейных эволюционных уравнений типа Бюргерса",
        "section": "НУ"
    },
    {
        "id": 38,
        "name": "Шерстюков Владимир Борисович, Костин Андрей Борисович",
        "from": "Московский государственный университет, Москва",
        "title": "Вычисление главного значения аргумента гамма-функции в чисто мнимых точках",
        "section": "КФА"
    },
    {
        "id": 39,
        "name": "Иванов Александр Валентинович, Харук Наталья Вячеславовна",
        "from": "Санкт-Петербургское отделение Математического института им. В.А.Стеклова РАН, Санкт-Петербург",
        "title": "О деформации фундаментального решения оператора Лапласа в контексте КТП",
        "section": "ДУ"
    },
    {
        "id": 40,
        "name": "Ковалевский Александр Альбертович",
        "from": "Институт математики и механики им. Н.Н. Красовского УрО РАН и Уральский федеральный университет, Екатеринбург",
        "title": "Variational inequalities with G-convergent nonlinear elliptic operators and measurable bilateral constraints",
        "section": "ДУ"
    },
    {
        "id": 41,
        "name": "Павлюк Юрий Алексеевич, Пышнограй\n\nГригорий Владимирович",
        "from": "Алтайский государственный педагогический университет, Барнаул\n\nАлтГТУ име-\n\nни И.И. Ползунова (Барнаул, Россия)",
        "title": "Реологическая модель для описания сходящихся течений полимерных систем",
        "section": "ММ"
    },
    {
        "id": 42,
        "name": "Султанов Оскар Анварович",
        "from": "ИМВЦ УФИЦ РАН (Уфа, Россия)",
        "title": "Бифуркации в гамильтоновых системах с затухающими стохастическими возмущениями",
        "section": "НУ"
    },
    {
        "id": 43,
        "name": "Костенко Ирина Вадимовна",
        "from": "Курский государственный университет, Курск",
        "title": "Об обобщенном уточненном порядке в смысле Валирона",
        "section": "КФА"
    },
    {
        "id": 44,
        "name": "Муллошараф Арабов Курбонович",
        "from": "Казанский (Приволжский) федеральный университет",
        "title": "Применение модели Transformer для моделирования и прогнозирования временных рядов",
        "section": "ММ"
    },
    {
        "id": 45,
        "name": "Насибуллин Рамиль Гайсаевич",
        "from": "Казанский федеральный университет, Казань",
        "title": "Неравенства типа Харди для объемного расстояния $N$-мерного шара",
        "section": "КФА"
    },
    {
        "id": 46,
        "name": "Лангаршоев Мухтор Рамазонович",
        "from": "АГЗ МЧС России, Московская область, г. Химки",
        "title": "ТОЧНЫЕ НЕРАВЕНСТВА ТИПА ДЖЕКСОНА-СТЕЧКИНА И ЗНАЧЕНИЯ ПОПЕРЕЧНИКОВ НЕКОТОРЫХ КЛАССОВ ФУНКЦИЙ В ПРОСТРАНСТВЕ 𝐿2",
        "section": "КФА"
    },
    {
        "id": 47,
        "name": "Ляхов Алексей Дмитриевич, Овчинников Александр Сергеевич, Бострем Ирина Геннадьевна, Синицын Владимир Евгеньевич",
        "from": "УрФУ имени Б.Н. Ельцина (Екатеринбург, Россия)",
        "title": "Система уравнений Паризи к модели Блюма-Каппеля",
        "section": "НУ"
    },
    {
        "id": 48,
        "name": "Максимов Владимир Петрович",
        "from": "Пермский государственный национальный исследовательский университет",
        "title": "О задачах управления для систем с дробными производными и последействием",
        "section": "ДУ"
    },
    {
        "id": 49,
        "name": "Кац Давид Борисович",
        "from": "Московский политехнический университет, Москва",
        "title": "On a new approach in the theory of Riemann boundary value problems on non-rectifiable curves",
        "section": "КФА"
    },
    {
        "id": 50,
        "name": "Маматов Акмал Равшанович",
        "from": "Самаркандский государственный университет имени Ш. Рашидова",
        "title": "Алгоритм решения максиминной задачи уклонения-сближения",
        "section": "ДУ"
    },
    {
        "id": 51,
        "name": "Сафонова Татьяна Анатольевна",
        "from": "Северный (Арктический) федеральный университет имени М.В. Ломоносова",
        "title": "О новых представлениях значений дзета-функции Римана в нечётных точках",
        "section": "СТО"
    },
    {
        "id": 52,
        "name": "Сабитов Камиль Басирович",
        "from": "УУНиТ (Стерлитамак, Россия)",
        "title": "Задача Дирихле для трехмерного уравнения смешанного типа, малые знаменатели",
        "section": "ДУ"
    },
    {
        "id": 53,
        "name": "Кулагин Антон Евгеньевич, Шаповалов Александр Васильевич",
        "from": "Томский политехнический университет, Томск",
        "title": "Квазиклассические решения нелокального нелинейного уравнения Шредингера с неэрмитовой частью, ассоциированные с динамикой квазичастиц",
        "section": "НУ"
    },
    {
        "id": 54,
        "name": "Шарипов Руслан Абдулович",
        "from": "Уфимский университет науки и технологий",
        "title": "Релятивистское ужёстчение и смягчение быстро движущихся пружин.",
        "section": "НУ"
    },
    {
        "id": 55,
        "name": "Мирзоев Карахан Агахан оглы, Конечная Наталья Николаевна",
        "from": "МГУ имени М.В. Ломоносова, Москва\n\nСАФУ имени М.В. Ломоносова, Архангельск",
        "title": "Об асимптотике решений одного класса линейных  дифференциальных уравнений с коэффициентами -- распределениями",
        "section": "СТО"
    },
    {
        "id": 56,
        "name": "Ravshan Radjabovich Ashurov, Нуралиева Навбахор Шаймардоновна",
        "from": "Institute of Mathematics named after V.I. Romanovsky of the Academy of Sciences of the Republic of Uzbekistan",
        "title": "Bitsadze-Samarskii problem for an elliptic equation in an infinite domain",
        "section": "ДУ"
    },
    {
        "id": 57,
        "name": "Шакиров Искандер Асгатович",
        "from": "Набережночелнинский государственный педагогический университет,  Набережные Челны",
        "title": "АППРОКСИМАЦИЯ КОНСТАНТЫ ЛЕБЕГА ОПЕРАТОРА ФУРЬЕ ЛОГАРИФМИЧЕСКОЙ ФУНКЦИЕЙ, СОДЕРЖАЩЕЙ ДВЕ ДРОБНО-РАЦИОНАЛЬНЫЕ СЛАГАЕМЫЕ",
        "section": "КФА"
    },
    {
        "id": 58,
        "name": "Насырова Дина Ахметовна",
        "from": "Институт механики им. Р.Р.Мавлютова УФИЦ РАН, Уфа",
        "title": "О собственных колебаниях жидкости в нефтяной скважине",
        "section": "ММ"
    },
    {
        "id": 59,
        "name": "Павленко Виктор Александрович",
        "from": "ИМВЦ УФИЦ РАН, Уфа",
        "title": "Построение решений аналогов временных уравнений Шредингера, соответствующих  паре гамильтоновых систем H(5/2+3/2) и H(5/2+2)",
        "section": "НУ"
    },
    {
        "id": 60,
        "name": "Хусенов Бехзод Эркин угли, Рахмонова Феруза Шокир кизи",
        "from": "Bukhara State University (Bukhara, Uzbekistan)",
        "title": "Properties of the 𝐴(𝑧)–harmonic majorant",
        "section": "КФА"
    },
    {
        "id": 61,
        "name": "Поляков Дмитрий Михайлович",
        "from": "ЮМИ ВНЦ РАН (Владикавказ, Рос-\n\nсия)",
        "title": "Об асимптотике собственных значений оператора четвертого порядка общего вида с параметром в граничных условиях",
        "section": "СТО"
    },
    {
        "id": 62,
        "name": "Литвинова Кристина Владиславовна, Литвинов Владислав Львович",
        "from": "Московский государственный университет, Москва\n\nSyzran branch of Samara State Technical University, Syzran",
        "title": "Mathematical modeling of stochastic oscillations of a string with a moving\n\nboundary",
        "section": "ММ"
    },
    {
        "id": 63,
        "name": "Гребенникова Ирина Владимировна",
        "from": "Уральский государственный горный университет, Екатеринбург",
        "title": "К задаче управления сингулярно возмущенной системой с запаздыванием при интегральных ограничениях",
        "section": "ДУ"
    },
    {
        "id": 64,
        "name": "Литвинова Кристина Владиславовна",
        "from": "Московский государственный университет, Москва",
        "title": "MATHEMATICAL MODELING OF STOCHASTIC OSCILLATIONS OF\n\nA VISCOELASTIC ROPE WITH A MOVING BOUNDARY",
        "section": "ММ"
    },
    {
        "id": 65,
        "name": "Солиев Юнус Солиевич",
        "from": "МАДИ, Москва",
        "title": "О лакунарных квадратурных формулах для сингулярного интеграла с ядром Коши",
        "section": "КФА"
    },
    {
        "id": 66,
        "name": "Джабраилов Ахмед Лечаевич",
        "from": "ЧГУ (Грозный, Россия)",
        "title": "ИССЛЕДОВАНИЕ ОБОБЩЕННОЙ КРАЕВОЙ ЗАДАЧИ\n\nДЛЯ ДИФФЕРЕНЦИАЛЬНОГО УРАВНЕНИЯ\n\nБЕСКОНЕЧНОГО ПОРЯДКА",
        "section": "ДУ"
    },
    {
        "id": 67,
        "name": "Фармонов Шерзодбек Рахмонжонович",
        "from": "Ферганский государственный универси-\n\nтет (Фергана, Узбекистан)",
        "title": "НЕЛОКАЛЬНЫЕ ЗАДАЧИ ДЛЯ УРАВНЕНИЯ\n\nСМЕШАННОГО ТИПА ВТОРОГО РОДА",
        "section": "ДУ"
    },
    {
        "id": 68,
        "name": "Рябов Кирилл Сергеевич",
        "from": "НИУ ВШЭ - Нижний Новгород (Нижний Новгород, Россия)",
        "title": "ИЗМЕНЕНИЕ ФОРМЫ ГАУССИАНА ФУНКЦИИ БРОСКА\n\nИГРОВЫХ КОСТЕЙ НА ПРИМЕРЕ ИГРЫ CATAN",
        "section": "ММ"
    },
    {
        "id": 69,
        "name": "Syalam Ali Wira Dinata",
        "from": "Institute of Applied Mathematics and Computer Science (IPMKN)-Tomsk State University, Tomsk Russia",
        "title": "Quadruple SARFIMA Models",
        "section": "ММ"
    },
    {
        "id": 70,
        "name": "Бугров Владимир Олегович, Рассадин Александр Эдуардович, Рубцов Максим Игоревич",
        "from": "ННМО (Нижний Новгород, Россия)",
        "title": "Распространение пандемии с двумя устойчивыми состояниями по пересеченной местности",
        "section": "ММ"
    },
    {
        "id": 71,
        "name": "Черкашин Дмитрий Андреевич, Вирченко Юрий Петрович",
        "from": "БГТУ имени В.Г. Шухова (Белгород, Россия)",
        "title": "ПОСТРОЕНИЕ АППРОКСИМАЦИЙ\n\nВ ЗАДАЧАХ ДИСКРЕТНОЙ ТЕОРИИ ПЕРКОЛЯЦИИ",
        "section": "ММ"
    },
    {
        "id": 72,
        "name": "Канонирова\n\nЕлизавета Петровна, Александр Янович, Шевелёв Александр Павлович",
        "from": "ТюмГУ (Тюмень, Россия)",
        "title": "ФОРМУЛИРОВКА ФИЗИКО-МАТЕМАТИЧЕСКОЙ\n\nМОДЕЛИ РАСПРОСТРАНЕНИЯ ВОЛН ГОРЕНИЯ И\n\nДЕТОНАЦИИ В ТРУБАХ С УЧАСТКАМИ\n\nПЕРЕМЕННОГО СЕЧЕНИЯ",
        "section": "ММ"
    },
    {
        "id": 73,
        "name": "Кропанев\n\nНикита Анатольевич, Горохов Александр Юрьевич, Каменских Анна Александровна, Шлыков Евгений Сергеевич, Абляз Тимур Ризович",
        "from": "ПНИПУ (Пермь, Россия)",
        "title": "МОДЕЛИРОВАНИЕ ПРОЦЕССА\n\nЭЛЕКТРОЭРОЗИОННОЙ ОБРАБОТКИ МАТЕРИАЛОВ\n\nНА ОСНОВЕ ТЕМПЕРАТУРНОЙ ЗАДАЧИ",
        "section": "ММ"
    },
    {
        "id": 74,
        "name": "Малютин Виктор Борисович, Нуржанов Бердах Орынбаевич",
        "from": "Институт математики Национальной академии наук Беларуси (Минск, Республика Беларусь)\n\nИнститут математики имени В.И. Романовского Академии наук Республики Узбекистан (Ташкент, Республика Узбеки-\n\nстан)",
        "title": "ПРИМЕНЕНИЕ КВАЗИКЛАССИЧЕСКОЙ\n\nАППРОКСИМАЦИИ К ОСНОВНОМУ КИНЕТИЧЕСКОМУ\n\nУРАВНЕНИЮ",
        "section": "ММ"
    },
    {
        "id": 75,
        "name": "Мышеловка\n\nЛариса Викторовна, Вирченко Юрий Петрович",
        "from": "БелГУ (Белгород, Россия)\n\nБГТУ имени В.Г.Шухова (Бел-\n\nгород, Россия)",
        "title": "СТАТИСТИЧЕСКИЙ ПОДХОД ОПИСАНИЯ\n\nРАДИАЦИОННО-КОНДУКТИВНОГО ТЕПЛООБМЕНА",
        "section": "ММ"
    },
    {
        "id": 76,
        "name": "Омарова Асият Гамзатовна",
        "from": "Дагестанский государственный университет (Махачкала, Россия)",
        "title": "ЧИСЛЕННОЕ РЕШЕНИЕ ТРЕТЬЕЙ\n\nНАЧАЛЬНО-КРАЕВОЙ ЗАДАЧИ ДЛЯ\n\nНЕСТАЦИОНАРНОГО УРАВНЕНИЯ\n\nТЕПЛОПРОВОДНОСТИ С ДРОБНЫМИ\n\nПРОИЗВОДНЫМИ",
        "section": "ММ"
    },
    {
        "id": 77,
        "name": "Пичугин Олег Николаевич,\n\nАмерханов Марат Инкилапович,\n\nФедоров Константин Михайлович,\n\nГильманов Александр Янович,\n\nШевелёв Александр Павлович",
        "from": "АГТУ ВШН (Альметьевск, Россия)\n\nПАО \"Татнефть\" (Альметьевск, Россия)\n\nТюмГУ (Тюмень, Россия) и АГТУ ВШН (Альметьевск, Россия)\n\nТюмГУ (Тюмень, Россия)",
        "title": "НОЛЬ-МЕРНАЯ ИНТЕГРАЛЬНАЯ МОДЕЛЬ\n\nПАРОГРАВИТАЦИОННОГО ДРЕНАЖА",
        "section": "ММ"
    },
    {
        "id": 78,
        "name": "Эгамов Альберт Исмаилович, Бураго Павел Николаевич",
        "from": "ННГУ им. Н.И. Лобачевского\n\n(Нижний Новгород, Россия)",
        "title": "МАКСИМИЗАЦИЯ ЭФФЕКТИВНОСТИ ПРЕДПРИЯТИЯ\n\nПРИ ОБНОВЛЕНИИ СРЕДСТВ ЗАЩИТЫ\n\nИНФОРМАЦИОННЫХ АКТИВОВ",
        "section": "ММ"
    },
    {
        "id": 79,
        "name": "Ячиков Игорь Михайлович, Шафиков Тимур Ильдусович",
        "from": "ИНиТ, ЮУрГУ (Челябинск, Россия)",
        "title": "МАТЕМАТИЧЕСКОЕ МОДЕЛИРОВАНИЕ ТЕПЛОВОГО\n\nСОСТОЯНИЯ СЛИТКА ПРИ РАЗНОЙ ФОРМЕ СТЕНОК\n\nСЛЯБОВОГО КРИСТАЛЛИЗАТОРА МНЛЗ",
        "section": "ММ"
    },
    {
        "id": 80,
        "name": "Хабибуллин Исмагил Талгатович, Хакимова Айгуль Ринатовна",
        "from": "ИМВЦ УФИЦ РАН (Уфа, Россия)",
        "title": "О ПОСТРОЕНИИ РЕШЕНИЙ НЕЛИНЕЙНЫХ\n\nИНТЕГРИРУЕМЫХ УРАВНЕНИЙ В ЧАСТНЫХ\n\nПРОИЗВОДНЫХ ПРИ ПОМОЩИ ОДЕВАЮЩИХ\n\nЦЕПОЧЕК",
        "section": "НУ"
    },
    {
        "id": 81,
        "name": "Гаянов Никита Владимирович, Парусникова Анастасия Владимировна",
        "from": "НИУ ВШЭ\n\n(Москва, Россия)",
        "title": "О СОДЕРЖАЩИХ ЛОГАРИФМЫ ФОРМАЛЬНЫХ\n\nРЕШЕНИЯХ Q-РАЗНОСТНЫХ УРАВНЕНИЙ",
        "section": "НУ"
    },
    {
        "id": 82,
        "name": "Кабанов Даниил Константинович, Екомасов Евгений Григорьевич, Самсонов Кирилл Юрьевич, Фахретдинов Марат Ирекович",
        "from": "УУНиТ (Уфа, Россия)\n\nТюменский государственный университет (Тюмень, Россия)\n\nУУНиТ (Уфа, Россия)",
        "title": "ДИНАМИКА КИНКОВ УРАВНЕНИЙ СИНУС-ГОРДОНА\n\nИ ϕ4 C ПРОСТРАНСТВЕННЫМИ НЕОДНОРОДНОСТЯМИ",
        "section": "НУ"
    },
    {
        "id": 83,
        "name": "Султанов Мурат Фаритович, Лукащук Станислав Юрьевич",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "Алгоритм численного моделирования распространения волн в среде, описываемой дробно-дифференциальной моделью Зинера",
        "section": "ДУ"
    },
    {
        "id": 84,
        "name": "Хакимов Аким Гайфуллинович",
        "from": "ИМех УФИЦ РАН (Уфа, Рос-\n\nсия)",
        "title": "НЕЛИНЕЙНЫЙ ИЗГИБ БАЛКИ",
        "section": "НУ"
    },
    {
        "id": 85,
        "name": "Sa’dulla\n\nTashpulatov",
        "from": "Institute of Nuclear Physics of the Academy of Science of\n\nRepublic of Uzbekistan, Tashkent, Uzbekistan",
        "title": "STRUCTURE OF ESSENTIAL SPECTRUM AND DISCRETE\n\nSPECTRA OF THE ENERGY OPERATOR OF\n\nFOUR-ELECTRON SYSTEMS IN THE IMPURITY\n\nHUBBARD MODEL. SECOND SINGLET STATE",
        "section": "СТО"
    },
    {
        "id": 86,
        "name": "Каримзода Дониер Джалилович.",
        "from": "Таджикский\n\nнациональный университет",
        "title": "ВАРИАНТЫ МЕТОДА И.М.ВИНОГРАДОВА В ЧИСЛОВОМ\n\nПОЛЕ",
        "section": "СТО"
    },
    {
        "id": 87,
        "name": "Alauadinov\n\nAmir, Yusupov\n\nBakhtiyor, Vaisova\n\nNafosat",
        "from": "V.I.Romanovskiy Institute of Mathematics\n\nUzbekistan Academy of Sciences (Nukus, Uzbekistan)",
        "title": "2-LOCAL DERIVATIONS OF THE N-TH SCHR ¨ ODINGER\n\nALGEBRAS",
        "section": "КФА"
    },
    {
        "id": 88,
        "name": "Невский Михаил Викторович",
        "from": "ЯрГУ им. П.Г. Демидова (Яро-\n\nславль, Россия)",
        "title": "ОЦЕНКИ ДЛЯ ИНТЕРПОЛЯЦИОННЫХ ПРОЕКТОРОВ\n\nИ МНОГОЧЛЕНЫ ЛЕЖАНДРА",
        "section": "КФА"
    },
    {
        "id": 89,
        "name": "Иванова Ольга Александровна, Мелихов Сергей Николаевич",
        "from": "ЮФУ (Ростов-на-Дону, Россия)",
        "title": "Адамаровские операторы в пространствах голоморфных функций полиномиального роста и бесконечно дифференцируемых вплоть до границы",
        "section": "КФА"
    },
    {
        "id": 90,
        "name": "Исаев Константин Петрович, Юлмухаметов Ринад Салаватович",
        "from": "УУНиТ (Уфа Россия)\n\nИМВЦ УФИЦ РАН (Уфа, Россия)",
        "title": "Об одной шкале банаховых пространств аналитических функций на выпуклой области",
        "section": "КФА"
    },
    {
        "id": 91,
        "name": "Малютин Константин Геннадьевич, Кабанко Михаил Владимирович",
        "from": "КГУ (Курск, Россия)",
        "title": "О ФУНКЦИЯХ ПЛОТНОСТИ",
        "section": "КФА"
    },
    {
        "id": 92,
        "name": "Даллул\n\nМариана",
        "from": "К(П)ФУ (Казань, Россия)",
        "title": "ХАРАКТЕРИЗАЦИЯ СЛЕДОВЫХ ФУНКЦИОНАЛОВ\n\nНЕРАВЕНСТВАМИ ДЛЯ РАЗМАХА МАТРИЦЫ",
        "section": "КФА"
    },
    {
        "id": 93,
        "name": "Хадур\n\nМахмуд",
        "from": "К(П)ФУ (Казань, Россия)",
        "title": "ОДИН МЕТОД ПОСТРОЕНИЯ ИДЕМПОТЕНТОВ В\n\nУНИТАЛЬНОЙ АЛГЕБРЕ",
        "section": "КФА"
    },
    {
        "id": 94,
        "name": "Наумова Алёна Александровна",
        "from": "КГУ (Курск, Россия)",
        "title": "ОБ ИНДИКАТОРЕ СУБГАРМОНИЧЕСКОЙ ФУНКЦИИ В\n\nНЕОГРАНИЧЕННОМ ПОЛУКОЛЬЦЕ",
        "section": "КФА"
    },
    {
        "id": 95,
        "name": "Хасанов Юсуфали Хасанович, Давлатов Ахлиддин Намозович",
        "from": "РТСУ (Душанбе, Республика Таджикистан)",
        "title": "О СУММИРУЕМОСТИ ПО МЕРЕ В ПРОСТРАНСТВЕ\n\nИЗМЕРИМЫХ ФУНКЦИЙ",
        "section": "КФА"
    },
    {
        "id": 96,
        "name": "Хасанов Юсуфали Хасанович, Талбаков Фарходджон Махмадшоевич",
        "from": "РТСУ (Душанбе, Республика Таджикистан)\n\nТГПУ (Душанбе, Республика Таджикистан)",
        "title": "О ДОСТАТОЧНЫХ УСЛОВИЯХ АБСОЛЮТНОЙ\n\nСХОДИМОСТИ ДВОЙНЫХ РЯДОВ ФУРЬЕ\n\nПОЧТИ-ПЕРИОДИЧЕСКИХ ФУНКЦИЙ БЕЗИКОВИЧА",
        "section": "КФА"
    },
    {
        "id": 97,
        "name": "V.I. Korzyuk, J.V. Rudzko",
        "from": "Belarusian State University, (Minsk,\n\nBelarus); Institute of Mathematics of the National Academy of Sciences\n\nof Belarus (Minsk, Belarus)\n\nInstitute of\n\nMathematics of the National Academy of Sciences of Belarus (Minsk, Belarus)",
        "title": "MIXED PROBLEM FOR A SEMILINEAR NONSTRICTLY\n\nHYPERBOLIC EQUATION OF THE THIRD ORDER",
        "section": "ДУ"
    },
    {
        "id": 98,
        "name": "Павлючик Павел Болеславович, Проневич Андрей Францевич",
        "from": "ГрГУ им. Янки Купалы (Гродно, Беларусь)",
        "title": "КЛАССЫ НЕАВТОНОМНЫХ ПОЛИНОМИАЛЬНЫХ\n\nМНОГОМЕРНЫХ ДИФФЕРЕНЦИАЛЬНЫХ СИСТЕМ С\n\nКРАТНЫМИ КОМПЛЕКСНОЗНАЧНЫМИ\n\nПОЛИНОМИАЛЬНЫМИ ЧАСТНЫМИ ИНТЕГРАЛАМИ",
        "section": "ДУ"
    },
    {
        "id": 99,
        "name": "Аминова Ася Васильевна, Хакимов Д.Р.",
        "from": "КФУ (Казань, Россия)",
        "title": "СИММЕТРИИ ПЯТИМЕРНЫХ ЖЕСТКИХ\n\nH-ПРОСТРАНСТВ H 32,2",
        "section": "ДУ"
    },
    {
        "id": 100,
        "name": "Артисевич Анжела Евгеньевна",
        "from": "АГУ (Майкоп, Россия)",
        "title": "Об управлении счетными спектрами характеристик колеблемости линейных однородных дифференциальных уравнений",
        "section": "ДУ"
    },
    {
        "id": 101,
        "name": "Байзаев Саттор, Баротов Рузибой Нумонжонович",
        "from": "ТГУПБП (Худжанд, Таджикистан)\n\nХГУ имени ак. Б. Гафурова\n\n(Худжанд, Таджикистан)",
        "title": "О МЕТОДЕ ПОСТРОЕНИЯ РЕГУЛЯРНЫХ РЕШЕНИЙ\n\nОДНОГО КЛАССА ОБОБЩЕННЫХ СИСТЕМ\n\nКОШИ-РИМАНА СО СЛАБО ОСЦИЛЛИРУЮЩИМИ\n\nКОЭФФИЦИЕНТАМИ",
        "section": "ДУ"
    },
    {
        "id": 102,
        "name": "Байзаев Саттор, Шокирова Мухбира Мухторхоновна",
        "from": "ТГУПБП (Худжанд, Таджикистан)",
        "title": "О РЕГУЛЯРНЫХ РЕШЕНИЯХ ОДНОГО КЛАССА\n\nМНОГОМЕРНЫХ ОБОБЩЕННЫХ СИСТЕМ\n\nКОШИ-РИМАНА С СИНГУЛЯРНЫМИ\n\nКОЭФФИЦИЕНТАМИ",
        "section": "ДУ"
    },
    {
        "id": 103,
        "name": "Джамалов Сирожиддин Зухриддинович, Шакиров Абдувасик Абдурахмон угли",
        "from": "ИМ им. В.И. Романовского АН Р. Уз. (Ташкент, Узбекистан)",
        "title": "ОБ ОДНОЙ КОЭФФИЦИЕНТНОЙ-ОБРАТНОЙ ЗАДАЧЕ С\n\nНЕЛОКАЛЬНЫМИ УСЛОВИЯМИ ДЛЯ ТРЕХМЕРНОГО\n\nУРАВНЕНИЯ ТРИКОМИ В ПАРАЛЛЕЛЕПИПЕДЕ",
        "section": "ДУ"
    },
    {
        "id": 104,
        "name": "Евхута Николай Александрович",
        "from": "ЮРГПУ (НПИ) им. М.И. Платова (Новочеркасск, Россия)",
        "title": "ПЕРИОДИЧЕСКИЕ РЕШЕНИЯ НЕКОТОРЫХ\n\nУРАВНЕНИЙ",
        "section": "ДУ"
    },
    {
        "id": 105,
        "name": "Зайнуллин Рифат Гильметдинович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "РЕШЕНИЕ ДВУХМЕРНОЙ ОДНОФАЗНОЙ ЗАДАЧИ\n\nСТЕФАНА ДЛЯ ПОЛУПОЛОСЫ",
        "section": "ДУ"
    },
    {
        "id": 106,
        "name": "Кадиркулов Бахтияр Жалилович, Бегимкулов\n\nФозил Хидирович",
        "from": "Alfraganus University, Tashkent, Uzbekistan; Академия наук Республики Узбекистан Институт математики имени В. И. Романовского\n\nTashkent Perfect University, Uzbekistan",
        "title": "ОБ ОДНОЗНАЧНОЙ РАЗРЕШИМОСТИ ЗАДАЧИ\n\nБИЦАДЗЕ-САМАРСКОГО ДЛЯ ВЫРОЖДАЮЩЕГОСЯ\n\nЭЛЛИПТИЧЕСКОГО УРАВНЕНИЯ",
        "section": "ДУ"
    },
    {
        "id": 107,
        "name": "Медведев Александр Викторович, Кузенков Олег Анатольевич",
        "from": "ННГУ имени Н.И. Лобачевского\n\n(Нижний Новгород, Россия)\n\nННГУ имени Н.И. Лобачевского\n\n(Нижний Новгород, Россия)",
        "title": "ПОЛИТИЧЕСКИЙ АСПЕКТ В КОНКУРЕНЦИИ ЯЗЫКОВ",
        "section": "ДУ"
    },
    {
        "id": 108,
        "name": "Мухсинов Едгор Мирзоевич, Хакимов Рахматджон Иномович",
        "from": "ТГУПБП (Худжанд, Таджикистан)\n\nХГУ имени академика Б. Гафурова (Худжанд, Таджикистан)",
        "title": "ЗАДАЧА ПРЕСЛЕДОВАНИЯ ДЛЯ ОДНОЙ\n\nДИФФЕРЕНЦИАЛЬНОЙ ИГРЫ С ДРОБНЫМИ\n\nПРОИЗВОДНЫМИ ХИЛЬФЕРА",
        "section": "ДУ"
    },
    {
        "id": 109,
        "name": "Насыров Фарит Сагитович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "Потраекторно-детерминированный принцип максимума для СДУ в случае нефиксированного времени",
        "section": "ДУ"
    },
    {
        "id": 110,
        "name": "Чадромцев Никита Денисович",
        "from": "НТУ \"Сириус\", Сочи",
        "title": "Portfolio optimization with turnover modeling",
        "section": "ММ"
    },
    {
        "id": 111,
        "name": "Артыкбаева Жанар Нурланкызы",
        "from": "Казахский национальный университет имени аль-Фараби, Алматы",
        "title": "Multi-point Boundary Problems",
        "section": "ДУ"
    },
    {
        "id": 112,
        "name": "Николаева Надежда Геннадьевна, Манакова Наталья Александровна",
        "from": "Южно-Уральский государственный университет, Челябинск",
        "title": "Особенности фазового многообразия математической модели Хоффа",
        "section": "ДУ"
    },
    {
        "id": 113,
        "name": "Авилтай Наурызбай",
        "from": "Казахский национальный университет имени аль-Фараби, Алматы",
        "title": "Асимптотическое разложение решения задачи для сингулярно возмущенных линейных импульсных системы с сингулярными импульсами",
        "section": "ДУ"
    },
    {
        "id": 114,
        "name": "Кожевникова Лариса Михайловна",
        "from": "Стерлитамакский филиал Уфимского университета науки и технологий",
        "title": "Локальное ренормализованное решение эллиптического уравнения с переменными показателями в пространстве R^n",
        "section": "НУ"
    },
    {
        "id": 115,
        "name": "Корчагин Павел Анатольевич, Абдуллаев Фатхулла Хабибулаевич, Алфимов Георгий Леонидович",
        "from": "НИУ МИЭТ, Москва\n\nФТИ АН Узбекистана\n\n(Ташкент, Узбекистан)\n\nНИУ МИЭТ (Москва, Россия); ИМВЦ УФИЦ РАН (Уфа, Россия)",
        "title": "Intrinsic localized modes in DNLS-type equations with competing nonlinearities",
        "section": "НУ"
    },
    {
        "id": 116,
        "name": "Бегматов Акрам Хасанович, Исмоилов Алишер Сидикович",
        "from": "СБУМИПТК (Ташкент,\n\nУзбекистан)\n\nУзбекско-Финский педагогический институт, Узбекистан",
        "title": "Задача интегральной геометрии Вольтерровского типа по семейству сфер в пространстве",
        "section": "ММ"
    },
    {
        "id": 117,
        "name": "Даулетбай Бекарыс Нуркенович",
        "from": "Казахский национальный университет имени аль-Фараби, Алматы, Казахстан",
        "title": "Спектральный анализ многоточечных краевых задач для оператора двухкратного дифференцирования и задача граничного управления для уравнения Гуртина-Пипкина",
        "section": "СТО"
    },
    {
        "id": 118,
        "name": "Сакиева Альфия Ураловна",
        "from": "Институт математики с ВЦ УФИЦ РАН, Уфа",
        "title": "Об интегрируемых редукциях нелинейных уравнений типа двумеризованной цепочки Тоды",
        "section": "НУ"
    },
    {
        "id": 119,
        "name": "Россовский Григорий Леонидович",
        "from": "РТУ МИРЭА/РУДН, Москва",
        "title": "Задача Коши с суммируемой начальной функцией для параболического оператора с пространственными сдвигами в потенциалах",
        "section": "ДУ"
    },
    {
        "id": 120,
        "name": "Олими Абдуманнон Гафорзода, Охунзода Нозимджон Кобил",
        "from": "Худжандский государственный университет имени академика Б. Гафурова, Худжанд, Таджикистан",
        "title": "Интегральное представление общего решения и граничные задачи для одного обыкновенного дифференциального уравнения специального типа",
        "section": "ДУ"
    },
    {
        "id": 121,
        "name": "Зезюлин Дмитрий Александрович, Алфимов Георгий Леонидович",
        "from": "Университет ИТМО (Санкт-\n\nПетербург, Россия)\n\nНИУ МИЭТ (Москва, Россия); ИМВЦ УФИЦ РАН (Уфа, Россия);",
        "title": "Нелинейные моды в квазипериодических потенциалах",
        "section": "НУ"
    },
    {
        "id": 122,
        "name": "Валеев Нурмухамет Фуатович, Султанаев Яудат Талгатович",
        "from": "ИМВЦ УФИЦ РАН, Уфа\n\nБГПУ имени М. Акмуллы (Уфа,\n\nРоссия)",
        "title": "Обратная спектральная задача для одномерного оператора Шредингера на целой оси с неполными данными.",
        "section": "СТО"
    },
    {
        "id": 123,
        "name": "Атанов Артем Викторович",
        "from": "Воронежский государственный университет, Воронеж",
        "title": "Об одной «уникальной» 6-мерной алгебре Ли в задаче об однородности в C4",
        "section": "КФА"
    },
    {
        "id": 124,
        "name": "Раутиан Надежда Александровна",
        "from": "Московский государственный университет, Москва",
        "title": "О свойствах решений вольтерровых интегро-дифференциальных уравнений в гильбертовых пространствах",
        "section": "ДУ"
    },
    {
        "id": 125,
        "name": "Васильев Владимир Борисович, Гебресласи Хадиш",
        "from": "Белгородский государственный национальный исследовательский университет, Белгород",
        "title": "ОБ ЭЛЛИПТИЧЕСКОМ УРАВНЕНИИ В МНОГОМЕРНОЙ ОБЛАСТИ С РАЗРЕЗОМ",
        "section": "ДУ"
    },
    {
        "id": 126,
        "name": "Ильясов Явдат Шавкатович",
        "from": "ИМВЦ УФИЦ РАН, Уфа",
        "title": "Метод продолженного отношения Рэлея, приложения к линейным и нелинейным задачам",
        "section": "НУ"
    },
    {
        "id": 127,
        "name": "Мухамадиев Эргашбой Мирзоевич, Нуров Исхокбой Джумаевич",
        "from": "ВоГУ (Вологда, Россия)\n\nТаджикский национальный университет, Душанбе",
        "title": "Качественный анализ фазовых портретов и возникновение предельных циклов в системах дифференциальных уравнений с переключением",
        "section": "ДУ"
    },
    {
        "id": 128,
        "name": "Напалков Валерий Валентинович, Нуятов Андрей Александрович",
        "from": "ИМВЦ УФИЦ РАН, Уфа\n\nНГТУ имени Р.Е. Алексеева (Нижний Новгород, Россия)",
        "title": "Об условии подобия гильбертовых пространств с воспроизводящим ядром",
        "section": "КФА"
    },
    {
        "id": 129,
        "name": "Тахиров Жозил Останович,\n\nАнваржонов Бунёдбек Баходирович",
        "from": "Институт Математики АН РУз, Ташкент\n\nИнститут Математики АН РУз, Ташкент",
        "title": "On a free boundary model of eco-epidemiological interactions with predator intervention and infections",
        "section": "ММ"
    },
    {
        "id": 130,
        "name": "Родикова Евгения Геннадьевна, Кислакова Ксения Васильевна",
        "from": "Брянский государственный университет имени академика И.Г. Петровского (Брянск, Россия)",
        "title": "Об интерполяции в классе М. Джрбашяна в единичном круге",
        "section": "КФА"
    },
    {
        "id": 131,
        "name": "Бегматов Акрам Хасанович, Утеулиев Ниетбай Утеулиевич,\n\nИсмоилов Алишер Сидикович",
        "from": "СБУМИПТК (Ташкент,\n\nУзбекистан)\n\nНФТУИТ (Нукус,\n\nУзбекистан)\n\nУз-ФинПИ (Самарканд, Узбекистан)",
        "title": "ЧИСЛЕННОЕ МОДЕЛИРОВАНИЕ ЗАДАЧИ\n\nИНТЕГРАЛЬНОЙ ГЕОМЕТРИИ ПО СЕМЕЙСТВУ\n\nПАРАБОЛ С ЗАДАННОЙ ВЕСОВОЙ ФУНКЦИЕЙ",
        "section": "ММ"
    },
    {
        "id": 132,
        "name": "Кашникова Анастасия Павловна, Мифтахов Эльдар Наилевич",
        "from": "СФ УУНиТ (Стерлитамак, Россия)\n\nУУНиТ (Уфа, Россия)",
        "title": "ИСПОЛЬЗОВАНИЕ ЭВРИСТИЧЕСКИХ МЕТОДОВ ДЛЯ\n\nРЕШЕНИЯ ЗАДАЧ ОПТИМИЗАЦИИ СЛОЖНЫХ\n\nФИЗИКО-ХИМИЧЕСКИХ ПРОЦЕССОВ",
        "section": "ММ"
    },
    {
        "id": 133,
        "name": "Ким Андрей Сергеевич, Гильманов Александр Янович, Шевелев Александр Павлович",
        "from": "ООО «Альянс» (Тюмень, Россия)\n\nТюмГУ (Тюмень, Россия)",
        "title": "Моделирование теплообмена между перспективными конструкциями термостабилизатора и сваи",
        "section": "ММ"
    },
    {
        "id": 134,
        "name": "Кризский Владимир Николаевич, Александров Павел Николаевич, Владов Михаил Львович",
        "from": "СПГУ (Санкт-Петербург,\n\nРоссия)\n\nЦГЭМИ ИФЗ РАН им. О.Ю.\n\nШмидта (Москва, Троицк, Россия)\n\nМГУ им. М.В. Ломоносова\n\n(Москва, Россия)",
        "title": "ОБ АЛГОРИТМЕ УТОЧНЕНИЯ ФОРМЫ И ТЕНЗОРА\n\nУДЕЛЬНОЙ ЭЛЕКТРОПРОВОДНОСТИ ЛОКАЛЬНОЙ\n\nНЕОДНОРОДНОСТИ ПО ДАННЫМ ГЕОЛЕКТРИКИ",
        "section": "ММ"
    },
    {
        "id": 135,
        "name": "Доброхотов Сергей Юрьевич",
        "from": "ИПМех РАН (Москва, Россия)",
        "title": "Асимптотики собственных функций оператора Бельтрами-Лапласа с вырождением метрики на границе и нелинейные береговые волны",
        "section": "СТО"
    },
    {
        "id": 136,
        "name": "Шнурников Игорь Николаевич",
        "from": "НТУ Сириус (Сочи, Россия)",
        "title": "МОДЕЛИ ДЛЯ ОБОРОТА ИНВЕСТИЦИОННОГО\n\nПОРТФЕЛЯ, ЗАВИСЯЩИЕ ОТ КОВАРИАЦИЙ\n\nДОХОДНОСТЕЙ",
        "section": "ММ"
    },
    {
        "id": 137,
        "name": "Sultanaev Yaudat Talgatovich, Valeev Nurmukhamet Fuatovich, Yeskermessuly Alibek",
        "from": "BSPU (Ufa, Russia)\n\nInstitute of Mathematics with Computing Centre - SUFRC RAS\n\n(Ufa, Russia)\n\nAltynsarin Institute (Arkalyk, Kazakhstan)",
        "title": "ASYMPTOTIC SOLUTIONS OF THE STURM-LIOUVILLE\n\nEQUATION IN VECTOR-FUNCTION SPACE",
        "section": "ДУ"
    },
    {
        "id": 138,
        "name": "Каримов Камолиддин Туйчибоевич, Шокиров Асрор Муроджонович",
        "from": "ФерГУ (Фергана, Узбекистан)",
        "title": "ЗАДАЧА ФРАНКЛЯ ДЛЯ УРАВНЕНИЯ С\n\nСИНГУЛЯРНЫМ КОЭФФИЦИЕНТОМ В ТРЕХМЕРНОЙ\n\nОБЛАСТИ",
        "section": "ДУ"
    },
    {
        "id": 139,
        "name": "Тимергалиев Самат Низаметдинович",
        "from": "КГАСУ (Казань, Россия)",
        "title": "О РАЗРЕШИМОСТИ НЕЛИНЕЙНЫХ КРАЕВЫХ ЗАДАЧ\n\nРАВНОВЕСИЯ НЕПОЛОГИХ АНИЗОТРОПНЫХ\n\nОБОЛОЧЕК ТИПА ТИМОШЕНКО С\n\nНЕЗАКРЕПЛЕННЫМИ КРАЯМИ",
        "section": "ДУ"
    },
    {
        "id": 140,
        "name": "Турсунов Фарход Рузикулович",
        "from": "СамГУ (Самарканд,\n\nУзбекистан);",
        "title": "РЕГУЛЯРИЗАЦИЯ ЗАДАЧА КОШИ ДЛЯ ЛИНЕЙНЫХ\n\nЭЛЛИПТИЧЕСКИХ СИСТЕМ ПЕРВОГО ПОРЯДКА С\n\nПОСТОЯННЫМИ КОЭФФИЦИЕНТАМИ",
        "section": "ДУ"
    },
    {
        "id": 141,
        "name": "Abdelkder Senouci",
        "from": "Ibn-Khaldoun University of Tiaret, Algeria",
        "title": "HARDY TYPE INEQUALITIES IN GRAND LEBESGUE\n\nSPACES 𝐿𝑝), 0 < 𝑝 ≤ 1 FOR QUASI-MONOTONE FUNCTIONS",
        "section": "КФА"
    },
    {
        "id": 142,
        "name": "Брайчев Георгий Генрихович",
        "from": "РУДН, МПГУ (Москва, Россия)",
        "title": "Новая формула для радиуса полноты системы экспоненциальных мономов",
        "section": "КФА"
    },
    {
        "id": 143,
        "name": "Гайсин Рашит Ахтярович",
        "from": "ИМВЦ УФИЦ РАН\n\n(Уфа, Россия)",
        "title": "ТЕОРЕМА ТИПА БАНГА И КРИТЕРИЙ\n\nКВАЗИАНАЛИТИЧНОСТИ ДЛЯ СЛАБО\n\nРАВНОМЕРНЫХ ОБЛАСТЕЙ",
        "section": "КФА"
    },
    {
        "id": 144,
        "name": "Абдрахманов Самат Илдусович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "О нелинейных стохастических уравнениях параболического типа",
        "section": "ДУ"
    },
    {
        "id": 145,
        "name": "Шарипов Бобоали, Джумаев Эраж Хакназарович",
        "from": "ТГФЭУ (Душанбе, Таджикистан)\n\nФилиал МГУ имени М.В. Ломоносова в г. Душанбе (Душанбе, Таджикистан)",
        "title": "О представлении решений одного класса систем дифференциальных уравнений в комплексной плоскости",
        "section": "НУ"
    },
    {
        "id": 146,
        "name": "Баззаев\n\nАлександр Казбекович",
        "from": "North Ossetian State University, Vladikavkaz, Russia; Vladikavkaz Institute of Management, Vladikavkaz, Russia",
        "title": "О СХОДИМОСТИ РАЗНОСТНЫХ СХЕМ ДЛЯ\n\nНЕЛОКАЛЬНЫХ КРАЕВЫХ ЗАДАЧ С ПРОИЗВОДНОЙ\n\nДРОБНОГО ПОРЯДКА",
        "section": "ДУ"
    },
    {
        "id": 147,
        "name": "Полякова Анна Петровна",
        "from": "Институт математики им. С.Л. Соболева, Новосибирский государственный университет, Новосибирск",
        "title": "Реконструкция 2D векторного поля по лучевым преобразованиям его моментов",
        "section": "ММ"
    },
    {
        "id": 148,
        "name": "Полякова Анна Петровна, Деревцов Евгений Юрьевич",
        "from": "Институт математики им. С.Л. Соболева, Новосибирский государственный университет, Новосибирск",
        "title": "О структуре и связях 2D тензорных полей малого ранга",
        "section": "ММ"
    },
    {
        "id": 149,
        "name": "Калинин Алексей Вячеславович, Тюхтина Алла Александровна",
        "from": "Национальный исследовательский Нижегородский государственный университет им. Н.И. Лобачевского, Нижний Новгород",
        "title": "Метод ортогонального проектирования в обратных задачах об источнике",
        "section": "ДУ"
    },
    {
        "id": 150,
        "name": "Бадулина Нина Александровна",
        "from": "Московский государственный университет им. М. В. Ломоносова, Москва",
        "title": "Стоун-чеховская компактификация пространств мер: первая аксиома счетности",
        "section": "КФА"
    },
    {
        "id": 151,
        "name": "Кунгиров Мамур",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "О БИФУРКАЦИИ ЦИКЛОВ НА БЕСКОНЕЧНОСТИ В СИСТЕМАХ C ОДНОРОДНЫМИ НЕЛИНЕЙНОСТЯМИ",
        "section": "НУ"
    },
    {
        "id": 152,
        "name": "Бавшин Тимур Русланович, Сухов Артем Сергеевич, Хитров Егор Германович",
        "from": "ИТМО (Санкт-Петербург, Россия)",
        "title": "ИССЛЕДОВАНИЕ ЧИСЛЕННЫХ МЕТОДОВ ОПТИМИЗАЦИИ ДЛЯ РЕШЕНИЯ ЗАДАЧИ ОПРЕДЕЛЕНИЯ ДЕФЕКТОВ КОСТРУКЦИОННЫХ МАТЕРИАЛОВ",
        "section": "ММ"
    },
    {
        "id": 153,
        "name": "Мальцева Светлана Васильевна, Деревцов Евгений Юрьевич",
        "from": "ИМ СО РАН, НГУ (Новосибирск, Россия)",
        "title": "О моделировании поведения криволинейных лучей в 2D неоднородных средах",
        "section": "КФА"
    },
    {
        "id": 154,
        "name": "Евтина Диана Сергеевна, Жабко Алексей Петрович",
        "from": "СПбГУ (Санкт-Петербург, Россия)",
        "title": "Исследование устойчивости систем дифференциальных уравнений с запаздыванием нейтрального типа",
        "section": "ДУ"
    },
    {
        "id": 155,
        "name": "Файрузов Махмут Эрнстович",
        "from": "Уфимский университет науки и технологий, Уфа",
        "title": "Об одной задаче оптимального управления для эллиптической системы с нелинейным состоянием, со смешанным граничным условием",
        "section": "ММ"
    },
    {
        "id": 156,
        "name": "Старцев Сергей Яковлевич",
        "from": "Институт математики с ВЦ УФИЦ РАН, Уфа",
        "title": "Об интегралах для интегрируемых по Дарбу уравнений в частных производных",
        "section": "НУ"
    },
    {
        "id": 157,
        "name": "Ломакина Елена Николаевна, Деревянко Олеся Сергеевна",
        "from": "ВЦ ДВО РАН (Хабаровск, Россия)\n\nДВГУПС (Хабаровск, Россия)",
        "title": "Оценки норм оператора Харди, действующего в пространствах Лоренца",
        "section": "КФА"
    },
    {
        "id": 158,
        "name": "Сомова Полина Анатольевна, Гурина Елена Ивановна",
        "from": "ММФ ТГУ (Томск, Россия)\n\nММФ ТГУ (Томск, Россия)",
        "title": "Исследование процесса теплопередачи в стеклопакетах с использованием математического и компьютерного моделирования",
        "section": "ММ"
    },
    {
        "id": 159,
        "name": "Назирова Эльвира Айратовна, Валеева Лейсян Нурмухаметовна, Марданов Булат Ильфатович",
        "from": "УУНИТ, Уфа\n\nМГУ (Москва, Россия)\n\nБГПУ им. М.Акмуллы (Уфа, Россия)",
        "title": "Уравнение Штурма-Лиувилля с быстро осциллирующим убывающим потенциалом",
        "section": "СТО"
    },
    {
        "id": 160,
        "name": "Зацепин Даниил Кириллович",
        "from": "Рязанский государственный университет имени С. А. Есенина",
        "title": "Применение теоремы о замкнутом графике к анализу устойчивости рекуррентных нейронных сетей",
        "section": "КФА"
    },
    {
        "id": 161,
        "name": "Шаршуков Владислав Алексеевич",
        "from": "Санкт-Петербургский государственный университет, Санкт-Петербург",
        "title": "Вариационный подход к построению оценки перерегулирования управляемой динамической системы",
        "section": "ДУ"
    },
    {
        "id": 162,
        "name": "Фот Николай Сергеевич, Винарский Александр Анатольевич",
        "from": "Оренбургский государственный Университет, Оренбург\n\nМФТИ (НИУ) (Москва, Россия)",
        "title": "Подходы для генерации данных при проведении социологических исследований",
        "section": "ММ"
    },
    {
        "id": 163,
        "name": "Болтачев Андрей Владимирович",
        "from": "Российский университет дружбы народов имени Патриса Лумумбы, Москва",
        "title": "Об эллиптичности операторов со скручиваниями",
        "section": "ДУ"
    },
    {
        "id": 164,
        "name": "Гайсин Ахтяр Магазович",
        "from": "ИМВЦ УФИЦ РАН, Уфа",
        "title": "ЗАДАЧИ ПО ЦЕЛЫМ ФУНКЦИЯМ, СВЯЗАННЫЕ С РЕГУЛЯРНЫМ РОСТОМ РЯДОВ ДИРИХЛЕ И ИНТЕРПОЛЯЦИЕЙ",
        "section": "КФА"
    },
    {
        "id": 165,
        "name": "Гайсина Галия Ахтяровна",
        "from": "УУНиТ, Уфа",
        "title": "УСТОЙЧИВОСТЬ МАКСИМАЛЬНОГО ЧЛЕНА ЦЕЛОГО РЯДА ДИРИХЛЕ, РОСТ КОТОРОГО КОНТРОЛИРУЕТСЯ ВЫПУКЛОЙ МАЖОРАНТОЙ ТОЛЬКО НА ДИСКРЕТНОМ МНОЖЕСТВЕ",
        "section": "КФА"
    },
    {
        "id": 166,
        "name": "Клевцова Юлия Юрьевна",
        "from": "Институт систем информатики им. А. П. Ершова СО РАН, Сибирский государственный  университет телекоммуникаций и информатики",
        "title": "Об оценках гладкости на решения одной модели атмосферы",
        "section": "ДУ"
    },
    {
        "id": 167,
        "name": "Жабко Алексей Петрович, Гапонов Дмитрий Николаевич",
        "from": "СПбГУ (Санкт-Петербург, Россия)",
        "title": "Оценка области гурвицевости полиномов в пространстве коэффициентов",
        "section": "ДУ"
    },
    {
        "id": 168,
        "name": "Шамматова Анастасия Анатольевна, Исламова Алиса Ильдаровна, Галиакбарова Эмилия Вильевна",
        "from": "УГНТУ (Уфа, Рос-\n\nсия)\n\nСНЭМА - СЕРВИС (Уфа, Россия)\n\nУГНТУ (Уфа, Рос-\n\nсия)",
        "title": "Фильтрация флюида в трещине конечной длины",
        "section": "ДУ"
    },
    {
        "id": 169,
        "name": "Жабко Алексей Петрович, Жабко Наталия Алексеевна",
        "from": "Санкт-Петербургский государственный университет, Санкт-Петербург",
        "title": "Условие Ляпунова для однородных систем",
        "section": "ДУ"
    },
    {
        "id": 170,
        "name": "Жуйков Константин Николаевич, Савин Антон Юрьевич",
        "from": "РУДН (Москва, Россия)",
        "title": "Об $\\eta$-инвариантах эллиптических краевых задач",
        "section": "СТО"
    },
    {
        "id": 171,
        "name": "Шавлуков Азамат Мавлетович",
        "from": "Институт математики с вычислительным центром УФИЦ РАН, Уфа",
        "title": "Об особенностях решений уравнений гидродинамического граничного слоя при стремлении вязкости к нулю",
        "section": "НУ"
    },
    {
        "id": 172,
        "name": "Толстых Маргарита Анатольевна",
        "from": "Донецкий государственный университет",
        "title": "Идентификация структуры социальной сети в диффузионной модели",
        "section": "ММ"
    },
    {
        "id": 173,
        "name": "Саввина Елена Игоревна",
        "from": "Воронежский государственный технический университет, Воронеж",
        "title": "Решение изопериметрической задачи для невыпуклой оболочки",
        "section": "ММ"
    },
    {
        "id": 174,
        "name": "Бегматов Акбар Хасанович",
        "from": "СибУПК (Новосибирск, Россия)",
        "title": "ВОССТАНОВЛЕНИЕ ФУНКЦИИ ПО ЕЕ\n\nИНТЕГРАЛЬНЫМ ДАННЫМ НА ЛЯМБДА-ЛИНИЯХ",
        "section": "ДУ"
    },
    {
        "id": 175,
        "name": "Борисов Денис Иванович, Сулейманов\n\nРадим Радикович",
        "from": "ИМВЦ УНЦ РАН (Уфа, Россия)\n\nУУНиТ (Уфа, Россия)",
        "title": "ОБ ОПЕРАТОРНЫХ ОЦЕНКАХ ДЛЯ ЭЛЛИПТИЧЕСКИХ\n\nУРАВНЕНИЙ В ДВУМЕРНЫХ ОБЛАСТЯХ С БЫСТРО\n\nОСЦИЛЛИРУЮЩЕЙ ГРАНИЦЕЙ И ЧАСТОЙ СМЕНОЙ\n\nКРАЕВЫХ УСЛОВИЙ",
        "section": "ДУ"
    },
    {
        "id": 176,
        "name": "Бутерин Сергей Александрович",
        "from": "СГУ (Саратов, Россия)",
        "title": "ОБ УСПОКОЕНИИ СИСТЕМЫ УПРАВЛЕНИЯ С\n\nГЛОБАЛЬНЫМ ПОСЛЕДЕЙСТВИЕМ НА\n\nБЕСКОНЕЧНОМ ВРЕМЕННОМ ГРАФЕ-ЗВЕЗДЕ",
        "section": "ДУ"
    },
    {
        "id": 177,
        "name": "Великанов Петр Геннадьевич, Хуан Жуй",
        "from": "КНИТУ-КАИ (Казань, Россия)",
        "title": "УНИВЕРСАЛЬНАЯ АППРОКСИМИРУЮЩАЯ\n\nФУНКЦИЯ ДЛЯ ПОЛУЧЕНИЯ ФУНДАМЕНТАЛЬНЫХ\n\nРЕШЕНИЙ ДИФФЕРЕНЦИАЛЬНЫХ УРАВНЕНИЙ И\n\nСИСТЕМ В ЧАСТНЫХ ПРОИЗВОДНЫХ",
        "section": "ДУ"
    },
    {
        "id": 178,
        "name": "Евхута Николай Александрович",
        "from": "ЮРГПУ (НПИ) им. М.И. Платова (Новочеркасск, Россия)",
        "title": "ПЕРИОДИЧЕСКИЕ РЕШЕНИЯ НЕКОТОРЫХ\n\nУРАВНЕНИЙ",
        "section": "ДУ"
    },
    {
        "id": 179,
        "name": "Зверева Маргарита Борисовна",
        "from": "ВГУ, ВГПУ (Воронеж, Россия)",
        "title": "КАЧЕСТВЕННЫЕ СВОЙСТВА РЕШЕНИЯ\n\nВАРИАЦИОННОГО НЕРАВЕНСТВА ДЛЯ РАЗРЫВНОЙ\n\nСТИЛТЬЕСОВСКОЙ СТРУНЫ",
        "section": "ДУ"
    },
    {
        "id": 180,
        "name": "Кангужин Балтабек Есматович, Кайырбек Жалгас Аскарович, Мустафина Мергуль Оралбековна",
        "from": "Al-Farabi Kazakh National University, Almaty, Kazakhstan\n\nEast Kazakhstan State University named after S. Amanzholova,\n\nKazakhstan",
        "title": "О ЗАДАЧЕ КОШИ ДЛЯ УРАВНЕНИЯ\n\nШТУРМА-ЛИУВИЛЛЯ НА ГРАФ-ЗВЕЗДЕ",
        "section": "ДУ"
    },
    {
        "id": 181,
        "name": "Кудрявцев Константин Николаевич, Изместьев Игорь Вячеславович",
        "from": "ЮУрГУ (НИУ) (Челябинск, Россия)\n\nЧелГУ (Челябинск, Россия)",
        "title": "СЛАБОЕ РАВНОВЕСИЕ ПО БЕРЖУ В\n\nПОЛИМАТРИЧНОЙ ИГРЕ С НЕЧЕТКОЙ ЦЕЛЬЮ",
        "section": "ДУ"
    },
    {
        "id": 182,
        "name": "Сафаров Джумабой, Миратов Сафархон Кудратович",
        "from": "Бох.ГУ (Бохтар, Республика Таджикистан)",
        "title": "ЗАДАЧА О СОБСТВЕННЫХ ЗНАЧЕНИЯХ ДЛЯ\n\nДВОЯКОПЕРИОДИЧЕСКИХ РЕШЕНИЙ УРАВНЕНИЯ\n\nОБОБЩЕННЫХ АНАЛИТИЧЕСКИХ ФУНКЦИЙ",
        "section": "ДУ"
    },
    {
        "id": 183,
        "name": "Халхаджаев Бахтиёр Батырович, Джамалов Сирожиддин Зухриддинович",
        "from": "ИМ им. В.И. Романовского АН\n\nР. Уз. (Ташкент, Узбекистан)",
        "title": "ОБ ОДНОЙ ЛИНЕЙНОЙ ОБРАТНОЙ ЗАДАЧЕ С\n\nНЕЛОКАЛЬНЫМИ КРАЕВЫМИ УСЛОВИЯМИ\n\nПЕРИОДИЧЕСКОГО ТИПА ДЛЯ ТРЕХМЕРНОГО\n\nУРАВНЕНИЯ СМЕШАННОГО ТИПА ВТОРОГО РОДА\n\nЧЕТВЕРТОГО ПОРЯДКА В ПАРАЛЛЕЛЕПИПЕДЕ",
        "section": "ДУ"
    },
    {
        "id": 184,
        "name": "Шамсудинов Файзулло Мамадуллоевич, Иззатуллоев Дости, Ганиев\n\nМуродбек",
        "from": "Бох. ГУ (Бохтар,\n\nРеспублика Таджикистан)",
        "title": "ИНТЕГРАЛЬНЫЕ ПРЕДСТАВЛЕНИЯ РЕШЕНИЙ ДЛЯ\n\nОДНОЙ ПЕРЕОПРЕДЕЛЕННОЙ СИСТЕМЫ\n\nДИФФЕРЕНЦИАЛЬНЫХ УРАВНЕНИЙ ПЕРВОГО\n\nПОРЯДКА С СИНГУЛЯРНОЙ ТОЧКОЙ",
        "section": "ДУ"
    },
    {
        "id": 185,
        "name": "Абанин Александр Васильевич",
        "from": "ЮФУ (Ростов-на-Дону,\n\nРоссия); ЮМИ ВНЦ РАН (Владикавказ, Россия)",
        "title": "СХОДИМОСТЬ ДИЛАТАЦИЙ, ПЛОТНОСТЬ\n\nПОЛИНОМОВ И БИДВОЙСТВЕННОСТЬ В ВЕСОВЫХ\n\nПРОСТРАНСТВАХ ГОЛОМОРФНЫХ ФУНКЦИЙ",
        "section": "КФА"
    },
    {
        "id": 186,
        "name": "Абузярова Наталья Фаирбаховна",
        "from": "ИМ с ВЦ УФИЦ РАН (Уфа, Россия)",
        "title": "ОБ УСТОЙЧИВОСТИ (НЕ)ПОЛНОТЫ\n\nЭКСПОНЕНЦИАЛЬНЫХ СИСТЕМ В ПРОСТРАНСТВАХ\n\nУЛЬТРАДИФФЕРЕНЦИРУЕМЫХ ФУНКЦИЙ И\n\nУЛЬТРАРАСПРЕДЕЛЕНИЙ БЕРЛИНГА-БЬОРКА\n\nНОРМАЛЬНОГО ТИПА НА ИНТЕРВАЛЕ",
        "section": "КФА"
    },
    {
        "id": 187,
        "name": "Одинабеков Джасур Музофирович",
        "from": "Филиал МГУ имени М.В. Ломоносова в\n\nгороде Душанбе (Таджикистан)",
        "title": "О НЕТЕРОВОСТИ И ИНДЕКСЕ НЕКОТОРЫХ КЛАССАХ\n\nДВУМЕРНЫХ СИНГУЛЯРНЫХ ИНТЕГРАЛЬНЫХ ОПЕРАТОРОВ С\n\nНЕПРЕРЫВНЫМИ КОЭФФИЦИЕНТАМИ",
        "section": "КФА"
    },
    {
        "id": 188,
        "name": "Шабалин Павел Леонидович",
        "from": "КГАСУ (Казань, Россия)",
        "title": "КРАЕВАЯ ЗАДАЧА РИМАНА ДЛЯ ОБОБЩЕННОЙ\n\nСИСТЕМЫ КОШИ-РИМАНА С СВЕРХСИНГУЛЯРНОЙ\n\nЛИНИЕЙ",
        "section": "КФА"
    },
    {
        "id": 189,
        "name": "Александрова Елизавета Андреевна, Нагаева Зиля Мунировна, Башмаков Рустэм Абдрауфович",
        "from": "УУНИТ (Уфа, Россия)",
        "title": "О ПРИМЕНЕНИИ МЕТОДА ПОСЛЕДОВАТЕЛЬНОЙ\n\nСМЕНЫ СТАЦИОНАРНЫХ СОСТОЯНИЙ",
        "section": "ММ"
    },
    {
        "id": 190,
        "name": "Васильева Ольга Александровна",
        "from": "НИУ МГСУ (Москва, Россия)",
        "title": "СРАВНЕНИЕ ВРЕМЕНИ ПРОВЕДЕНИЯ НЕКОТОРЫХ\n\nХИМИЧЕСКИХ РЕАКЦИЙ, ОПИСЫВАЕМЫХ\n\nКИНЕТИЧЕСКИМИ СИСТЕМАМИ",
        "section": "ММ"
    },
    {
        "id": 191,
        "name": "Галиакбарова Эмилия Вильевна, Хакимова Зульфия Разифовна, Пангаева Анастасия Олеговна",
        "from": "УГНТУ (Уфа, Россия)",
        "title": "ФИЛЬТРАЦИЯ ФЛЮИДА В ТРЕЩИНЕ КОНЕЧНОЙ\n\nДЛИНЫ",
        "section": "ММ"
    },
    {
        "id": 192,
        "name": "Евсеев Федор Александрович",
        "from": "ЮГУ (Ханты-Мансийск, Россия)",
        "title": "РЕГУЛЯРНАЯ РАЗРЕШИМОСТЬ ПЕРВОЙ\n\nНАЧАЛЬНО-КРАЕВОЙ ЗАДАЧИ ДЛЯ УРАВНЕНИЙ\n\nКВАЗИГИДРОДИНАМИКИ В ПРИБЛИЖЕНИИ МЕЛКОЙ\n\nВОДЫ",
        "section": "ММ"
    },
    {
        "id": 193,
        "name": "Лискина Екатерина Юрьевна, Алыпов Алексей Викторович",
        "from": "РГУ имени С.А. Есенина (Рязань, Россия)",
        "title": "ФУНКЦИИ ИНВЕСТИЦИЙ И СБЕРЕЖЕНИЙ В МОДЕЛИ В. ЧАНГА — Д. СМИТА НА ПРИМЕРЕ ЦЕНТРАЛЬНОГО ФЕДЕРАЛЬНОГО ОКРУГА",
        "section": "ММ"
    },
    {
        "id": 194,
        "name": "Шморган С.А.",
        "from": "ЮГУ (Ханты-Мансийск, Россия)",
        "title": "Численное решение обратной задачи об определении точечных источников",
        "section": "ММ"
    },
    {
        "id": 195,
        "name": "Zhaidary\n\nMyrzakulova, Zakariyeva Zaruyet, Ratbay Myrzakulov",
        "from": "ЕНУ имени Л.Н Гумилева, Астана, Казахстан\n\nЗападно-Казахстанский университет имени М. Утемисова, Орал, Казахстан",
        "title": "INTEGRABLE MOTION OF TWO INTERACTING CURVES AND RELATED SPIN SYSTEMS",
        "section": "НУ"
    },
    {
        "id": 196,
        "name": "Акманова Светлана Владимировна",
        "from": "МГТУ им. Г.И. Носова (Магнитогорск, Россия)",
        "title": "ПОСТРОЕНИЕ ДОПУСТИМЫХ УПРАВЛЕНИЙ В НЕЛИНЕЙНЫХ ГИБРИДНЫХ СИСТЕМАХ",
        "section": "НУ"
    },
    {
        "id": 197,
        "name": "Асхабов Султан Нажмудинович",
        "from": "МФТИ (Долгопрудный, Россия), ЧГПУ (Грозный, Россия)",
        "title": "СИСТЕМЫ ИНТЕГРАЛЬНЫХ УРАВНЕНИЙ С ПОЧТИ ВОЗРАСТАЮЩИМИ ЯДРАМИ И СТЕПЕННОЙ НЕЛИНЕЙНОСТЬЮ",
        "section": "НУ"
    },
    {
        "id": 198,
        "name": "Васильева Ольга Александровна, Хохонов Александр Алексеевич",
        "from": "МГСУ (Москва, Россия)",
        "title": "ИССЛЕДОВАНИЕ ДИНАМИКИ НАЧАЛЬНОЙ СТАДИИ\n\nРАЗРУШЕНИЯ ОДНОРОДНОГО ОБРАЗЦА ПОД\n\nТЕМПЕРАТУРНЫМ ВОЗДЕЙСТВИЕМ",
        "section": "НУ"
    },
    {
        "id": 199,
        "name": "Сучкова Дилара Айратовна",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "Об обобщенном уравнении Кортевега – де Фриза с шумом в дисперсионном члене",
        "section": "НУ"
    },
    {
        "id": 200,
        "name": "Хоитметов Умид Азадович, Cобиров Шехзод Кучкарбой угли",
        "from": "УрГУ (Ургенч, Узбекистан)",
        "title": "О НАХОЖДЕНИИ РЕШЕНИЯ ЗАДАЧИ КОШИ ДЛЯ\n\nНАГРУЖЕННОГО УРАВНЕНИЯ МКДФ С\n\nСАМОСОГЛАСОВАННЫМ ИСТОЧНИКОМ В СЛУЧАЕ\n\nПРОСТЫХ СОБСТВЕННЫХ ЗНАЧЕНИЙ",
        "section": "НУ"
    },
    {
        "id": 201,
        "name": "Абдивохидов Азамат Авазхон угли, Хасанов Акназар Бекдурдиевич, Хасанов Темур Гафуржанович",
        "from": "СамГУ (Самарканд, Узбекистан)\n\nУрГУ (Ургенч, Узбекистан)",
        "title": "О МОДИФИЦИРОВАННОМ УРАВНЕНИИ\n\nКОРТЕВЕГА-ДЕ ФРИЗА-ЛИУВИЛЛЯ (ОМКДФ–Л)\n\nОТРИЦАТЕЛЬНОГО ПОРЯДКА",
        "section": "СТО"
    },
    {
        "id": 202,
        "name": "Kanguzhin Baltabek, Dosmagulova Karlygash",
        "from": "Al-Farabi Kazakh National University (Almaty, Kazakhstan),\n\nInstitute of Mathematics and Mathematical Modeling (Almaty, Kazakhstan)",
        "title": "DELTA-SHAPED PERTURBATIONS OF THE\n\nLAPLACE-BELTRAMI OPERATOR ON A SHERE WITH CUTS",
        "section": "СТО"
    },
    {
        "id": 203,
        "name": "Kuliga Andrew",
        "from": "Sirius, Russia",
        "title": "EMPIRICS ON PORTFOLIO TURNOVER",
        "section": "СТО"
    },
    {
        "id": 204,
        "name": "Ишкин Хабир Кабирович, Амангильдин Тагир Газизович",
        "from": "УУНиТ (Уфа, Россия)\n\nУУНиТ (Уфа, Россия)",
        "title": "О КОЭФФИЦИЕНТАХ РЕЛЕЯ–ШРЕДИНГЕРА ДЛЯ\n\nСОБСТВЕННЫХ ЗНАЧЕНИЙ РЕГУЛЯРНЫХ\n\nВОЗМУЩЕНИЙ АНГАРМОНИЧЕСКОГО ОСЦИЛЛЯТОРА",
        "section": "СТО"
    },
    {
        "id": 205,
        "name": "Ишкин Хабир Кабирович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "ОБ ОДНОМ АНАЛОГЕ ТЕОРЕМЫ АМБАРЦУМЯНА",
        "section": "СТО"
    },
    {
        "id": 206,
        "name": "Қайрат Г.",
        "from": "Казахский Национальный Университет имени Аль-Фараби",
        "title": "ЕДИНСТВЕННОСТЬ И РАЗРЕШИМОСТЬ ОДНОЙ ЧЕТЫРЕХ\n\nТОЧЕЧНОЙ ЗАДАЧИ ДЛЯ УРАВНЕНИЯ ТЕПЛОПРОВОДНОСТИ",
        "section": "СТО"
    },
    {
        "id": 207,
        "name": "Черепанова Елена Анатольевна",
        "from": "ЧелГУ (Челябинск, Россия)",
        "title": "О РАСХОДИМОСТИ НОРМАЛИЗУЮЩИХ РЯДОВ ДЛЯ\n\nВЫРОЖДЕННЫХ ОСОБЫХ ТОЧЕК БИНАРНЫХ\n\nДИФФЕРЕНЦИАЛЬНЫХ УРАВНЕНИЙ",
        "section": "ДУ"
    },
    {
        "id": 208,
        "name": "Вершинина Дарья Александровна, Федоров Владимир Евгеньевич",
        "from": "Челябинский государственный университет (Челябинск, Россия)",
        "title": "О ПОРОЖДЕНИИ РАЗРЕШАЮЩИХ СЕМЕЙСТВ\n\nОПЕРАТОРОВ УРАВНЕНИЙ С ПРОИЗВОДНОЙ\n\nРИМАНА — ЛИУВИЛЛЯ",
        "section": "ДУ"
    },
    {
        "id": 209,
        "name": "Баширова Зейна Анваровна, Абушахмина Гульфия Ринатовна, Сидельников Артем Викторович, Нигматуллин Равиль Рашидович, Будников\n\nГерман Константинович",
        "from": "УУНиТ (Уфа, Россия)\n\nБГМУ (Уфа, Россия)\n\nКАИ (Уфа, Россия)\n\nКФУ (Казань, Россия)",
        "title": "НОВЫЙ СПОСОБ КОЛИЧЕСТВЕННОГО ОПИСАНИЯ ГЛАВНЫХ КОМПОНЕНТ ВОЛЬТАМПЕРОМЕТРИЧЕСКИХ БАЗ ДАННЫХ «ЭЛЕКТРОННОГО ЯЗЫКА» С ИСПОЛЬЗОВАНИЕМ ДРОБНО-РАЦИОНАЛЬНЫХ ФУНКЦИЙ",
        "section": "ММ"
    },
    {
        "id": 210,
        "name": "Богданова Анастасия Петровна, Каменских Анна Александровна, Носов Юрий Олегович",
        "from": "ПНИПУ (Пермь, Россия)",
        "title": "ПОСТРОЕНИЕ МОДЕЛЕЙ ВЯЗКОУПРУГОГО И ВЯЗКОУПРУГОПЛАСТИЧЕСКОГО ПОВЕДЕНИЯ АНТИФРИКЦИОННЫХ ПОЛИМЕРНЫХ И СМАЗОЧНЫХ МАТЕРИАЛОВ, ПРИМЕНЯЕМЫХ В МОСТОСТРОЕНИИ",
        "section": "ММ"
    },
    {
        "id": 211,
        "name": "Васенина Наталья Анатольевна, Галина Галия Кабировна, Гареев Ильдар Альбертович, Лебедев Кирилл Олегович, Харламова Юлия Андреевна, Мингазов Денис Ленарович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "ТЕМПОРАЛЬНЫЕ ПОДХОДЫ К ИСЛЕДОВАНИЮ ЭЛЕКТРОХИМИЧЕСКИХ ДИНАМИЧЕСКИХ СИСТЕМ С ИСПОЛЬЗОВАНИЕМ ПРОЕКЦИОННЫХ МЕТОДОВ ХЕМОМЕТРИКИ",
        "section": "ММ"
    },
    {
        "id": 212,
        "name": "Баширова Зейна Анваровна, Бижанова Гулия Габдинуровна, Смольникова Ольга Геннадиевна, Васенина Наталья Анатольевна, Черняева\n\nЕлена Юрьевна, Султанов Гериан Рустамович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "НОВЫЙ ПОДХОД К ОЦЕНКЕ ТЕМПОРАЛЬНЫХ ПАРАМЕТРОВ ОБРАЗОВАНИЯ/РАСТВОРЕНИЯ НАНОСТРУКТУРНЫХ КОМПОЗИТОВ, НА ПРИМЕРЕ МИКРОПЛЕНОК АЛЮМИНИЯ, ПО МЕТОДУ PLS-T’",
        "section": "ММ"
    },
    {
        "id": 213,
        "name": "Шарипова Лилия Дамировна, Квятковская Адель Станиславовна, Гареев Ильдар Альбертович, Васенина Наталья Анатольевна, Черданцев Игорь Юрьевич, Зеркина Анастасия Васильевна",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "АНАЛИЗ ТЕМПОРАЛЬНЫХ ОТКЛИКОВ МЕТАЛЛОКСИДНОГО \"ЭЛЕКТРОННОГО НОСА\"ПО МЕТОДУ ПРОЕКЦИЙ НА ЛАТЕНТНЫЕ СТРУКТУРЫ ВОЛЬТАМПЕРОМЕТРИЧЕСКИХ БАЗ ДАННЫХ",
        "section": "ММ"
    },
    {
        "id": 214,
        "name": "Шарипова Лилия Дамировна, Ганиева Екатерина Сергеевна, Сидельникова Екатерина Артемовна, Васенина Наталья Анатольевна, Халимов Артур Айратович, Денисова Альбина Анасовна",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "РСА-ТЕМПОРАЛЬНЫЙ ОНЛАЙН АНАЛИЗ ВОЛЬТАМПЕРОМЕТРИЧЕСКОГО ПОВЕДЕНИЯ СЛИВОК В УСЛОВИЯХ ИХ РАЗБАВЛЕНИЯ ДИСТИЛЛИРОВАННОЙ ВОДОЙ",
        "section": "ММ"
    },
    {
        "id": 215,
        "name": "Морозкин Николай Данилович, Морозкин Никита Николаевич, Галлямов Всеволод Викторович",
        "from": "УУНиТ (Уфа, Россия)",
        "title": "РАСЧЕТ ТЕМПЕРАТУРНОГО ПОЛЯ В ПОРИСТОМ ПЛАСТЕ С ВЯЗКОПЛАСТИЧНОЙ НЕФТЬЮ",
        "section": "ММ"
    },
    {
        "id": 216,
        "name": "Максютова Эльза Ильдусовна, Сякаев Роман Сергеевич, Баширова Зейна Анваровна, Сабурова Юлия Борисовна, Мустафин Ахат Газизьянович, Юмагулов Марат Гаязович",
        "from": "АО \"УАП \"Гидравлика\" (Уфа, Россия)\n\nУУНиТ (Уфа, Россия)",
        "title": "ТЕМПОРАЛЬНЫЙ ПОДХОД К ТЕРМОДИНАМИЧЕСКОМУ АНАЛИЗУ ЭЛЕКТРОДНЫХ ПРОЦЕССОВ С УЧАСТИЕМ СЛЕДОВ КОМПОНЕНТОВ ДИНАМИЧЕСКИХ СИСТЕМ, НА ПРИМЕРЕ «ЭЛЕКТРОД/ЭЛЕКТРОАКТИВНЫЙ ПОЛИМЕР/БАВ»",
        "section": "ММ"
    },
    {
        "id": 217,
        "name": "Нигматуллин Равиль Рашидович, Баширова Зейна Анваровна, Максютова Эльза Ильдусовна, Бурганова Алина Рустамовна, Игнатьева Галина Анатольевна, Будников\n\nГерман Константинович",
        "from": "КАИ (Уфа, Россия)\n\nУУНиТ (Уфа, Россия)\n\nАО \"УАП \"Гидравлика\" (Уфа, Россия)\n\nМАОУ Лицей № 6 (Уфа, Россия)\n\nКФУ (Казань, Россия)",
        "title": "ОЦЕНКА ТЕМПОРАЛЬНОЙ СТРУКТУРЫ ФРАКТАЛИЗАЦИИ СТЕКЛОУГЛЕРОДА В УСЛОВИЯХ ВОЛЬТАМПЕРОМЕТРИЧЕСКОГО АНАЛИЗА ПО МЕТОДУ ГЛАВНЫХ КОМПОНЕНТ",
        "section": "ММ"
    },
    {
        "id": 218,
        "name": "Пья Пьо Аунг, Григорьев Юрий Всеволодович",
        "from": "МГТУ им. Н.Э. Баумана, (Москва, Россия)",
        "title": "НЕЛИНЕЙНОЕ ВЗАИМОДЕЙСТВИЕ УЛЬТРАЗВУКОВЫХ\n\nМЕДИЦИНСКИХ ИНСТРУМЕНТОВ С БИОЛОГИЧЕСКОЙ ТКАНЬЮ",
        "section": "ММ"
    },
    {
        "id": 219,
        "name": "Галиакбарова Эмилия Вильевна, Хакимова Зульфия Разифовна, Пангаева Анастасия Олеговна",
        "from": "УГНТУ (Уфа, Россия)",
        "title": "АКУСТИЧЕСКОЕ СКАНИРОВАНИЕ НИЗКОПРОНИЦАЕМЫХ ПЛАСТОВ С ТРЕЩИНАМИ ГРП ЧЕРЕЗ ПЕРЕСЕЧЕННУЮ СКВАЖИНУ С ФЛЮИДОМ",
        "section": "ММ"
    },
    {
        "id": 220,
        "name": "Kuralay Yesmakhanova, Zakariyeva Zaruyet, Zhassybayeva Meruyert, Ratbay Myrzakulov",
        "from": "L.N. Gumilyov ENU (Astana, Kazakhstan)\n\nU.M. Utemisov West Kazakhstan University (Oral, Kazakhstan)",
        "title": "MOTION OF ANISOTROPIC SPACE CURVES INDUCED BY THE LANDAU-LIFSHITZ EQUATION",
        "section": "НУ"
    },
    {
        "id": 221,
        "name": "Апушкинская Дарья Евгеньевна",
        "from": "РУДН (Москва, Россия)",
        "title": "О РАЗРЕШИМОСТИ ЗАДАЧИ С ГИСТЕРЕЗИСОМ",
        "section": "НУ"
    }
]